import { Component, EventEmitter, OnChanges, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';

import {
  PaginatedGridActionColumn,
  PaginatedGridColumnGroup,
  PaginatedGridDataColumn,
  PaginatedGridFilter,
} from '@models/search/paginated-grid-column.model';
import { PaginatedSearch } from '@models/search/paginated-search.model';
import { SearchSortParams } from '@models/search/search-params.model';

import { SearchService } from '@services/search.service';

@Component({
  selector: 'app-paginated-grid',
  templateUrl: './paginated-grid.component.html',
  styleUrls: ['./paginated-grid.component.scss'],
})
export class PaginatedGridComponent implements OnChanges {
  @Input() actionOptions: any[] = [];
  @Input({ required: true }) columnGroup!: PaginatedGridColumnGroup;
  @Input() dataSource: PaginatedSearch | nil;
  @Input() matSortActive: string = '';
  @Input() matSortDirection: SortDirection = 'asc';

  @Output() filterSortChange: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild(MatPaginator) matPaginator: MatPaginator | nil;
  @ViewChild(MatSort) matSort: MatSort | nil;

  constructor(private searchService: SearchService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource?.currentValue && !changes.dataSource.isFirstChange()) {
      this.searchService.updatePaginator(this.matPaginator, changes.dataSource.currentValue);
    }
  }

  public get columns(): PaginatedGridDataColumn[] {
    return Object.values(this.columnGroup.columns);
  }

  public get displayedColumns(): string[] {
    const displayColumns: string[] = this.columnGroup.displayedColumns;

    if (this.primaryActionColumn && this.actionOptions.length) {
      return ['action', ...displayColumns, 'controls'];
    } else if (this.primaryActionColumn) {
      return ['action', ...displayColumns];
    } else if (this.actionOptions) {
      return [...displayColumns, 'controls'];
    } else {
      return displayColumns;
    }
  }

  public get filteredColumns(): PaginatedGridDataColumn[] {
    return this.columns.filter((value: PaginatedGridDataColumn) => value.hasSelectedFilters);
  }

  public get primaryActionColumn(): PaginatedGridActionColumn | nil {
    return this.columnGroup.actionColumn;
  }

  public get searchSortParams(): SearchSortParams {
    return new SearchSortParams(
      this.matSort?.active ?? this.matSortActive,
      this.matSort?.direction ?? this.matSortDirection ?? 'asc',
      this.matPaginator ? this.matPaginator.pageIndex + 1 : 1,
      this.matPaginator?.pageSize,
    );
  }

  public showOldValues(action: string, details: any[]): boolean {
    let hasOldDetails: boolean = false;

    for (let i: number = 0; i < details.length; i++) {
      hasOldDetails = hasOldDetails || details[i].oldValue;
    }

    if (action === 'Create' || action === 'View' || (action === 'Modify' && !hasOldDetails)) {
      return false;
    }

    return true;
  }

  //#region Events

  public onFilterChange(filter: PaginatedGridFilter): void {
    filter.isSelected = !filter.isSelected;
    this.filterSortChange.emit();
  }

  public onSortChange(): void {
    this.filterSortChange.emit();
  }

  //#endregion
}
