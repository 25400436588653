import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AfterContentInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { ClientPracticeView } from '@models/client/client.model';
import { Practice } from '@models/practice.model';

import { CloneService } from '@services/utility/clone.service';
import { SessionContextService } from '@services/session-context.service';

@Component({
  selector: 'app-client-practice-tree',
  templateUrl: './client-practice-tree.component.html',
  styleUrls: ['./client-practice-tree.component.scss'],
})
export class ClientPracticeTreeComponent implements AfterContentInit, OnChanges, OnInit {
  @Input() allChecked: boolean = false;
  @Input({ required: true }) control!: AbstractControl;
  @Input() pmPracticeOnly: boolean = false; //stupid work around because we are too coupled to PM practices

  private data: ClientPracticeView[] = [];
  private flatNodeMap = new Map<any, any>();
  private nestedNodeMap = new Map<any, any>();
  private treeFlattener: MatTreeFlattener<any, any>;

  public checklistSelection = new SelectionModel<any>(true);
  public currentClientPracticeSelection: ClientPracticeView[] = [];
  public dataSource: MatTreeFlatDataSource<any, any>;
  public treeControl: FlatTreeControl<any>;

  constructor(
    private cloneService: CloneService,
    public sessionContextService: SessionContextService,
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<any>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.data = this.cloneService.deepClone(this.sessionContextService.clients);
  }

  ngOnInit(): void {
    this.dataSource.data = this.data.filter((value: ClientPracticeView) => value.practices?.length).map((value: ClientPracticeView) => this.mapClientToNode(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allChecked && !changes.allChecked.isFirstChange()) {
      this.onNodeSelect();
    }
  }

  ngAfterContentInit(): void {
    this.control.value?.forEach((client: ClientPracticeView) => {
      const clientNode: any = this.treeControl.dataNodes.find((node: any) => client.clientID == node.id);
      clientNode.viewAsClient = client.viewAsClient;
      if (!clientNode.expandable) {
        this.onSelectionToggle(clientNode, false);
      } else {
        const childNodes: any[] = this.treeControl.getDescendants(clientNode);
        childNodes.forEach((childNode: any) => {
          const practiceGUIDs: string[] = client.practices.map((value: Practice) => value.practiceGUID);
          if (practiceGUIDs.includes(childNode.id)) {
            this.onLeafSelectionToggle(childNode, false);
          }
        });
      }
    });
  }

  //#region Get

  public get isAllPartialSelected(): boolean {
    return (
      !this.allChecked &&
      this.checklistSelection.selected.length > 0 &&
      this.checklistSelection.selected.length != this.treeControl.dataNodes.length
    );
  }

  public get isAllSelected(): boolean {
    return this.allChecked || this.checklistSelection.selected.length == this.treeControl.dataNodes.length;
  }

  //#endregion

  //#region Events

  public onAllToggle(): void {
    if (this.isAllSelected) {
      this.checklistSelection.clear();
    } else {
      this.treeControl.dataNodes.forEach((node: any) => {
        this.checklistSelection.select(node);
      });
    }

    this.onNodeSelect();
  }

  public onLeafSelectionToggle(node: any, emitEvent: boolean = true): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.onNodeSelect(emitEvent);
  }

  private onNodeSelect(emitEvent: boolean = true): void {
    if (this.allChecked) {
      this.currentClientPracticeSelection = this.data;
    } else {
      const selectedClientNodes: any[] = this.treeControl.dataNodes.filter(
        (node: any) =>
          (this.checklistSelection.isSelected(node) || this.descendantsPartiallySelected(node)) && node.level == 0,
      );
      this.currentClientPracticeSelection = [];
      selectedClientNodes.forEach((node: any) => {
        const dataView: any = this.data.find((value: ClientPracticeView) => value.clientID == node.id);
        const clientPracticeView: ClientPracticeView = this.cloneService.deepClone(dataView);
        clientPracticeView.viewAsClient = node.viewAsClient;
        const practiceNodes: any[] = this.treeControl
          .getDescendants(node)
          .filter((practiceNode: any) => this.checklistSelection.isSelected(practiceNode));
        if (clientPracticeView.practices.length > 1) {
          clientPracticeView.practices = clientPracticeView.practices.filter((practiceView: Practice) =>
            practiceNodes.map((practiceNode: any) => practiceNode.id).includes(practiceView.practiceGUID),
          );
        }
        this.currentClientPracticeSelection.push(clientPracticeView);
      });
      this.control.setValue(this.currentClientPracticeSelection, { emitEvent: emitEvent });
    }
  }

  public onRemoved(event: any, node: any): void {
    this.onLeafSelectionToggle(node);
  }

  public onSelectionToggle(node: any, emitEvent: boolean = true): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);

    // Get entire list of selected items to emit
    this.onNodeSelect(emitEvent);
  }

  public onViewAsChange(event: MatSlideToggleChange, node: any): void {
    node.viewAsClient = !node.viewAsClient;
    this.onNodeSelect();
  }

  //#endregion

  //#region Tree

  /** Check all parent nodes in heirarchy */
  public checkAllParentsSelection(node: any): void {
    let parent: any | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check only root node in hierarchy */
  public checkRootNodeSelection(node: any): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Whether all the descendants of the node are selected. */
  public descendantsAllSelected(node: any): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  public descendantsPartiallySelected(node: any): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  public getParentNode(node: any): any | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  public getLevel = (node: any) => node.level;

  public getChildren = (node: any): any[] => node.children;

  public hasChild = (_: number, _nodeData: any) => _nodeData.expandable;

  public hasNoContent = (_: number, _nodeData: any) => _nodeData.item === '';

  public isExpandable = (node: any) => node.expandable;

  /** Transform 'ClientListItem' to 'node' based on presence of more than 1 'PracticeListItem' */
  private mapClientToNode(value: ClientPracticeView): any {
    const node: any = {
      item: value.displayName,
      extra: value.hdsNumber,
      id: value.clientID,
      children: [],
      viewAsClient: value.viewAsClient,
    };
    if (value.practices.length > 1) {
      node.children = value.practices.map((practice: Practice) => {
        return { item: practice.description, id: practice.practiceGUID, extra: practice.hdsNumber };
      });
    } else {
      node.item = value.displayName;
      node.extra = value.hdsNumber;
    }
    return node;
  }

  /** Transform 'node' into 'flateNode' for use in MatFlatTree */
  public transformer = (node: any, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : {};
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.extra = node.extra;
    flatNode.id = node.id;
    if (node.viewAsClient != null) {
      flatNode.viewAsClient = node.viewAsClient;
    }
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  //#endregion

  public practiceDisplay(client: ClientPracticeView): string {
    return client.practices
      .map((value: any) => {
        return `${value.description} (${value.hdsNumber})`;
      })
      .join(', ');
  }
}
