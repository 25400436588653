import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'trustHTML' })
export class TrustHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: string | nil) {
    if (value) {
      return this.sanitized.bypassSecurityTrustHtml(value);
    }

    return value;
  }
}
