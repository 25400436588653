import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appOverrideMaterialStyling]',
})
export class OverrideMaterialStylingDirective {
  constructor(elementRef: ElementRef, renderer: Renderer2) {
    renderer.addClass(elementRef.nativeElement, 'override-material-style');
  }
}
