import { Injectable } from '@angular/core';

import { UserPermissionEnum, UserPermissionLevelEnum } from '@enums/user-permission.enum';

import { IconType } from '@models/icon/icon.model';
import { SideNavItem } from '@models/side-nav-item.model';

import { UserSecurityService } from '@services/user-security.service';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  constructor(private userSecurityService: UserSecurityService) { }

  public buildClientSideNavItems(): SideNavItem[] {
    const sideNavItems: SideNavItem[] = [];

    sideNavItems.push(this.buildSideNavItem('Dashboard', 'house', '/client/dashboard', () => true));
    sideNavItems.push(
      this.buildSideNavItem('Reports', 'chartBarSimple', '/client/reports', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.consoleReporting, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('Audit', 'list', '/client/logging/clientAudit', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.consoleAudit, UserPermissionLevelEnum.view)
      )
    );
    sideNavItems.push(this.buildSideNavItem('Settings', 'settings', '/client/settings', () => true));

    return sideNavItems;
  }

  public buildSTIConsoleSideNavItems(): SideNavItem[] {
    const sideNavItems: SideNavItem[] = [];

    sideNavItems.push(this.buildSideNavItem('Dashboard', 'house', '/sti-console/dashboard', () => true));
    sideNavItems.push(
      this.buildSideNavItem('Reports', 'chartBarSimple', '/sti-console/reports', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.consoleReporting, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('Clients', 'buildings', '/sti-console/clients', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.clientManagement, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('Shards', 'database', '/sti-console/shards/shardManagement', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.shardManagement, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('CMMS API', 'shareNodes', '/sti-console/cmmsCures', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.cmmsApi, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('Terms', 'file', '/sti-console/terms/termsManagement', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.terms, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('Audit', 'list', '/sti-console/logging', () =>
        this.userSecurityService.hasPermission(UserPermissionEnum.consoleAudit, UserPermissionLevelEnum.view),
      ),
    );
    sideNavItems.push(
      this.buildSideNavItem('Settings', 'settings', '/sti-console/settings', () =>
        this.userSecurityService.hasConsoleSettingsAccess(),
      ),
    );

    return sideNavItems;
  }

  private buildSideNavItem(
    label: string,
    icon: IconType,
    routerLink: string,
    displayedCallback: () => boolean,
  ): SideNavItem {
    let item: SideNavItem = new SideNavItem();

    item.label = label;
    item.icon = icon;
    item.useAlternateStyle = true;
    item.routerLink = routerLink;
    item.isDisplayed = displayedCallback;

    return item;
  }
}
