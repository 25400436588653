<button
    class="text-button"
    [class.inverse]="inverseStyle"
    [class.small]="fontSize == 'small'"
    [class.medium]="fontSize == 'medium'"
    [class.large]="fontSize == 'large'"
    type="button"
    mat-button
    [color]="color"
    [matTooltip]="tooltip ?? ''"
    [matTooltipClass]="matTooltipClass ?? ''"
    [matTooltipPosition]="matTooltipPosition">
    @if (labelFirst) {
        <span>{{ label }}</span>
    }
    @if (iconConfig) {
        <app-icon
            [color]="color"
            [icon]="icon"
            [fontSize]="fontSize"
            [useAlternateStyle]="useAlternateStyle"
            [badgeLength]="badgeLength"
            [badgeColor]="badgeColor"
            [badgeSize]="badgeSize"
            [badgePosition]="badgePosition">
        </app-icon>
    }
    @if (!labelFirst) {
        <span>{{ label }}</span>
    }
</button>
