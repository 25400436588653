<mat-form-field
    [appearance]="appearance"
    [floatLabel]="floatLabel"
    [subscriptSizing]="subscriptSizing">
    @if (prefixIconType) {
        <app-icon
            matPrefix
            [icon]="prefixIconType" />
    }
    @if (label) {
        <mat-label>{{ label }}</mat-label>
    } @else if (placeholder) {
        <mat-label>{{ placeholder }}</mat-label>
    }

    @if (!multiselect) {
        <mat-select
            #selectElement
            disableOptionCentering
            [placeholder]="isLoading ? '' : placeholder"
            [compareWith]="compareWith"
            [formControl]="control"
            matTooltip="{{ selectedData ? selectedData : null }}"
            [matTooltipPosition]="tooltipPosition"
            appTooltipShowIfTruncated
            (selectionChange)="selectionChanged($event)">
            @if (blankOption) {
                <mat-option>None</mat-option>
            }
            @for (data of datasource; track data) {
                @if (isOptionGroup(data)) {
                    <mat-optgroup
                        [label]="data.groupLabel"
                        [disabled]="data.disabled">
                        @for (option of data.options; track option) {
                            <mat-option
                                #selectOption
                                class="truncate"
                                [value]="option"
                                matTooltip="{{
                                    tooltipProperty && option[tooltipProperty] ? option[tooltipProperty] : null
                                }}"
                                appTooltipShowIfTruncated
                                [matTooltipPosition]="tooltipPosition">
                                {{ displayPipe?.transform(displayProperty ? option[displayProperty] : option) }}
                            </mat-option>
                        }
                    </mat-optgroup>
                } @else {
                    <mat-option
                        [value]="data"
                        [matTooltip]="tooltipProperty && data[tooltipProperty] ? data[tooltipProperty] : null"
                        [matTooltipClass]="multilineTooltip ? 'multiline-tooltip-explicit mat-tooltip-fulllength' : ''"
                        [matTooltipPosition]="tooltipPosition">
                        {{ displayPipe?.transform(displayProperty ? data[displayProperty] : data) }}
                    </mat-option>
                }
            }
        </mat-select>
    } @else {
        <mat-select
            #selectElement
            disableOptionCentering
            multiple
            [compareWith]="compareWith"
            [formControl]="control"
            [placeholder]="isLoading ? '' : placeholder"
            matTooltip="{{ selectedData ? selectedData : null }}"
            [matTooltipPosition]="tooltipPosition"
            appTooltipShowIfTruncated
            (selectionChange)="selectionChanged($event)">
            @for (data of datasource; track data) {
                @if (isOptionGroup(data)) {
                    <mat-optgroup
                        [label]="data.groupLabel"
                        [disabled]="data.disabled">
                        @for (option of data.options; track option) {
                            <mat-option
                                #selectOption
                                class="truncate"
                                [value]="option"
                                matTooltip="{{
                                    tooltipProperty && option[tooltipProperty] ? option[tooltipProperty] : null
                                }}"
                                appTooltipShowIfTruncated
                                [matTooltipPosition]="tooltipPosition">
                                {{ displayPipe?.transform(displayProperty ? option[displayProperty] : option) }}
                            </mat-option>
                        }
                    </mat-optgroup>
                } @else {
                    <mat-option
                        [value]="data"
                        [matTooltip]="tooltipProperty && data[tooltipProperty] ? data[tooltipProperty] : null"
                        [matTooltipClass]="multilineTooltip ? 'multiline-tooltip-explicit mat-tooltip-fulllength' : ''"
                        [matTooltipPosition]="tooltipPosition">
                        {{ displayPipe?.transform(displayProperty ? data[displayProperty] : data) }}
                    </mat-option>
                }
            }
        </mat-select>
    }

    @if (showClear && control && control.value) {
        <app-icon-button
            matSuffix
            icon="close"
            fontSize="x-small"
            [color]="null"
            aria-label="Clear"
            [tabbable]="false"
            (click)="clear(); $event.stopPropagation()">
        </app-icon-button>
    }

    <!--Error-->
    @if (control?.invalid) {
        <mat-error>{{ getErrorMessage() }}</mat-error>
    }
</mat-form-field>
