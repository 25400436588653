import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirstLetter',
})
export class UppercaseFirstLetterPipe implements PipeTransform {
  transform(value: string | nil): string | nil {
    if (value) {
      return `${value[0].toUpperCase()}${value.substring(1)}`;
    }

    return null;
  }
}
