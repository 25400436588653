import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[appHideParentTooltip]',
})
export class HideParentTooltipDirective implements AfterViewInit {
  @Input() parentMatTooltip: MatTooltip | nil;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    fromEvent(this.elementRef.nativeElement, 'mouseenter').subscribe(() => {
      this.parentMatTooltip?.hide();
    });

    fromEvent(this.elementRef.nativeElement, 'mouseleave').subscribe(() => {
      this.parentMatTooltip?.show();
    });
  }
}
