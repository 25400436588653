import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AbstractControl, Validators } from '@angular/forms';

import { FormControlBase } from '@models/abstract/form-control-base.model';

import { ValidationMessageService } from '@services/validation-message.service';
import { CustomPatternValidator, PatternErrorMessageType } from '@validators/custom-pattern.validator';
import { RegularExpressionService } from '@services/utility/regular-expression.service';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
})
export class CurrencyInputComponent extends FormControlBase implements OnInit {
  @Input() max: number | nil;
  @Input() min: number | nil;
  @Input() maxDigits: number | nil;

  @ViewChild('inputElement') input: ElementRef | nil;

  constructor(
    validationMessageService: ValidationMessageService,
    private currencyValidator: CustomPatternValidator,
    private decimalPipe: DecimalPipe,
    private regularExpressionService: RegularExpressionService,
  ) {
    super(validationMessageService);
  }

  protected get(): string {
    return this.control.value.replace(/[^0-9.-]+/g, '');
  }

  ngOnInit() {
    this.control.setValue(this.decimalPipe.transform(this.control.value, '1.2-2'), { emitEvent: false });

    if (this.maxDigits) {
      const regex: string = `^-?(\\d|\\d,){1,${this.maxDigits}}(\\.\\d+)?$`;
      this.control.validator = Validators.compose([
        this.control.validator,
        this.currencyValidator.validate(regex, PatternErrorMessageType.value, 'Value too large').bind(this),
      ]);
    }
  }

  //#region Events

  public onFocus(event: FocusEvent): void {
    const input: string = this.get();
    this.control.setValue(input);

    //If the input is "empty" highlight it for easy editing
    if (input == '0.00') {
      const inputElement: HTMLInputElement = event.target as HTMLInputElement;
      inputElement.select();
    }
  }

  public onChange(event: Event): void {
    //Remove all but digits and decimal(s)
    const input: string = this.get();

    //If user pasted multiple decimals in, ignore all but the first
    let first: boolean = true;
    let cleaned: string = '';
    for (let index = 0; index < input.length; index++) {
      if (input[index] == '.') {
        if (!first) {
          continue;
        } else {
          first = false;
        }
      }
      cleaned += input[index];
    }

    if (this.min && Number(cleaned) < this.min) {
      cleaned = String(this.min);
    }

    if (this.max && Number(cleaned) > this.max) {
      cleaned = String(this.max);
    }

    this.control.setValue(this.decimalPipe.transform(cleaned, '1.2-2'), { emitEvent: false });
  }

  public onClear(): void {
    this.control.setValue('0.00');
    this.input?.nativeElement.focus();
  }

  public onKeyPress(event: KeyboardEvent, preventEnter: boolean): void {
    const char: string = event.key;

    //Prevent anything but digits and a single decimal
    if (preventEnter && (char.match(/[^0-9.-]+/g) || (char == '.' && this.control.value.includes('.', 0)))) {
      event.preventDefault();
    }
  }

  //#endregion

  //#region Utility

  public isRequired(): boolean {
    if (this.control?.validator) {
      const validator = this.control.validator({} as AbstractControl);

      if (validator && validator['required']) {
        return true;
      }
    }

    return false;
  }

  //#endregion
}
