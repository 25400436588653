<form
    #formDirective="ngForm"
    [formGroup]="control"
    class="form-row"
    autocomplete="off">
    <app-clearable-input
        class="form-col col-9"
        [control]="control.controls.number"
        [placeholder]="label" />

    @if (showExtension) {
        <app-clearable-input
            class="form-col col-3"
            [control]="control.controls.extension"
            placeholder="Ext" />
    }
</form>
