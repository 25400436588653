<ng-template cdk-portal="">
    <div
        #sheetContainer
        cdkTrapFocus
        class="side-area"
        [@slideInOut]="mode"
        (@slideInOut.start)="onSlideStart()"
        (@slideInOut.done)="onSlideFinished()"
        (click)="$event.stopPropagation()">
        <div class="side-content">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
