<div
    class="menu-trigger"
    [matMenuTriggerFor]="menu"
    #actionMenuTrigger="matMenuTrigger"
    [class.disabled]="isDisabled">
    <app-icon
        [icon]="icon"
        [color]="null"></app-icon>
</div>

<mat-menu
    #menu="matMenu"
    [overlapTrigger]="false">
    <ng-template matMenuContent>
        @for (item of model; track item) {
            <!--Click-->
            @if (item.onClick) {
                <div
                    mat-menu-item
                    [matTooltip]="item.tooltip()"
                    matTooltipPosition="after"
                    [class.no-pointer]="item.isDisabled()">
                    <div
                        (click)="item.onClick()"
                        [class.disabled]="item.isDisabled()">
                        @if (item.icon) {
                            <app-icon [icon]="item.icon"></app-icon>
                        }
                        <span>{{ item.label }}</span>
                    </div>
                </div>
            }
        }
    </ng-template>
</mat-menu>
