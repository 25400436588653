import { IconType } from '@models/icon/icon.model';

abstract class PaginatedGridColumn {
  matColumnDef: string = 'column';

  constructor(matColumnDef: string) {
    this.matColumnDef = matColumnDef;
  }
}

export class PaginatedGridDataColumn extends PaginatedGridColumn {
  public canSort: boolean = true;
  public description: string = '';
  public display: (element: any) => string | nil = (element: any) => '';
  public paginatedGridFilters: PaginatedGridFilter[] = [];
  public useTitleCase: boolean = true;

  constructor(
    matColumnDef: string,
    description: string,
    display: (element: any) => string | nil,
    canSort: boolean = true,
    paginatedGridFilters: string[] | any[] = [],
    useTitleCase: boolean = true,
  ) {
    super(matColumnDef);
    this.canSort = canSort;
    this.description = description;
    this.display = display;
    this.paginatedGridFilters = paginatedGridFilters.map((value: string | any) => typeof (value) === "string" ? new PaginatedGridFilter(value) : new PaginatedGridFilter(value.value));
    this.useTitleCase = useTitleCase;
  }

  public get hasSelectedFilters(): boolean {
    return this.paginatedGridFilters.filter((value: PaginatedGridFilter) => value.isSelected).length > 0;
  }

  public get selectedFilters(): string[] {
    return this.paginatedGridFilters
      .filter((value: PaginatedGridFilter) => value.isSelected)
      .map((value: PaginatedGridFilter) => value.filter);
  }

  public get selectedFiltersDisplay(): string {
    return this.selectedFilters.join(', ');
  }
}

export class PaginatedGridActionColumn extends PaginatedGridColumn {
  public icon: IconType;
  public isClickable: (element: any) => boolean = (element: any) => true;
  public rowClick: (element: any) => void = (element: any) => { };
  public tooltip: string = 'Action';

  constructor(
    icon: IconType,
    rowClick: (element: any) => void,
    tooltip: string,
    isClickable: (element: any) => boolean = (element: any) => true,
  ) {
    super('action');
    this.icon = icon;
    this.isClickable = isClickable;
    this.rowClick = rowClick;
    this.tooltip = tooltip;
  }
}

export class PaginatedGridFilter {
  public filter: string = '';
  public isSelected: boolean = false;

  constructor(filter: string, isSelected: boolean = false) {
    this.filter = filter;
    this.isSelected = isSelected;
  }
}

export class PaginatedGridColumnGroup {
  public actionColumn: PaginatedGridActionColumn | nil;
  public columns: { [key: string]: PaginatedGridDataColumn };
  public displayedColumns: string[] = [];

  constructor(
    columns: { [key: string]: PaginatedGridDataColumn },
    actionColumn?: PaginatedGridActionColumn,
    displayedColumns?: string[],
  ) {
    this.columns = columns;

    if (actionColumn) {
      this.actionColumn = actionColumn;
    }

    if (displayedColumns) {
      this.displayedColumns = displayedColumns;
    } else {
      this.displayedColumns = Object.values(this.columns).map((value: PaginatedGridDataColumn) => value.matColumnDef);
    }
  }

  public addColumn<K extends string & keyof { [key: string]: PaginatedGridDataColumn }>(
    name: K,
    column: Required<{ [key: string]: PaginatedGridDataColumn }>[K],
  ): void {
    this.columns[name] = column;
  }

  public getColumn(name: string): PaginatedGridDataColumn | nil {
    return this.columns[name];
  }
}
