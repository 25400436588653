<form
    #formDirective="ngForm"
    [formGroup]="control"
    autocomplete="off"
    class="form-row">
    <app-clearable-input
        [control]="control.controls.line1"
        class="form-col col-6"
        placeholder="Address line 1"></app-clearable-input>
    @if (showLine2) {
        <app-clearable-input
            [control]="control.controls.line2"
            class="form-col col-6"
            placeholder="Address line 2"></app-clearable-input>
    }
    <div class="form-row">
        <!--Postal Code-->
        <app-clearable-input
            [control]="control.controls.postalCode"
            class="form-col col-6"
            [placeholder]="postalCodeLabel"></app-clearable-input>
        <!--City-->
        <app-clearable-input
            [control]="control.controls.city"
            class="form-col col-6"
            placeholder="City"></app-clearable-input>
        <!--State/Province-->
        <app-drop-down-list
            [control]="control.controls.stateProvince"
            [datasource]="stateProvinces"
            class="form-col col-6"
            [placeholder]="stateProvinceLabel"
            displayProperty="name"
            [externalCompareWith]="stateCompareWith"></app-drop-down-list>
        <!--Country-->
        <app-drop-down-list
            [control]="control.controls.country"
            [datasource]="countries"
            class="form-col col-6"
            placeholder="Country"
            displayProperty="name"
            [externalCompareWith]="countryCompareWith"></app-drop-down-list>
    </div>
</form>
