import { Component, Input } from '@angular/core';

import { IconButtonBase } from '@models/icon/icon-button.model';
import { FontSize } from '@models/icon/icon.model';

@Component({
  selector: 'app-icon-text-button',
  templateUrl: './icon-text-button.component.html',
  styleUrls: ['./icon-text-button.component.scss'],
})
export class IconTextButtonComponent extends IconButtonBase {
  @Input() override fontSize: FontSize = 'medium';
  @Input() label: string | nil;
  @Input() labelFirst: boolean = false;
  @Input() inverseStyle: boolean = false;
}
