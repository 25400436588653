import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';

import { PaginatedSearch } from '@models/search/paginated-search.model';

@Component({
  selector: 'app-paginated-search',
  templateUrl: './paginated-search.component.html',
  styleUrl: './paginated-search.component.scss',
})
export class PaginatedSearchComponent implements AfterViewInit {
  @Input() displayWithFunction: (value: any) => string = () => '';
  @Input() label: string = 'Search';
  @Input() minimumLength: number = 3;
  @Input() placeholder: string = 'Search';
  @Input({ required: true }) searchFunction!: (searchTerm: string) => Observable<PaginatedSearch>;
  @Input() showResultCount: boolean = false;

  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  public datasource: Observable<any[]> = new Observable<any[]>();
  public formControl: FormControl<any> = new FormControl<any>(null);
  public isSearching: boolean = false;
  public paginatedResultsCount: number = 0;
  public totalResultsCount: number = 0;

  ngAfterViewInit(): void {
    this.datasource = this.formControl.valueChanges.pipe(
      debounceTime(500),
      filter((value: any) => typeof value === 'string' || value === null),
      distinctUntilChanged(),
      switchMap((value: string | nil) => {
        if (!value || value.length < this.minimumLength) {
          this.optionSelected.emit(null);
          return of([]);
        } else {
          this.isSearching = true;
          return this.searchFunction(value).pipe(
            tap((value: PaginatedSearch) => {
              this.isSearching = false;
              this.totalResultsCount = value.totalResultsCount ?? 0;
              this.paginatedResultsCount = value.results.length;
            }),
            map((value: PaginatedSearch) => value.results),
          );
        }
      }),
    );
  }

  //#region Events

  public onClearClick(emitEvent: boolean = true): void {
    this.formControl.setValue(null, { emitEvent: emitEvent });
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelected.emit(event.option.value);
  }

  //#endregion
}
