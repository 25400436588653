<div class="paginated-grid-header">
    <div class="paginated-grid-title">
        <ng-content select="[paginated-grid-title]"></ng-content>
    </div>
    <div class="paginated-grid-controls">
        <ng-content select="[paginated-grid-controls]"></ng-content>
    </div>
</div>

<app-paginated-grid
    [dataSource]="dataSource"
    [actionOptions]="actionOptions"
    [columnGroup]="columnGroup"
    [matSortActive]="matSortActive"
    [matSortDirection]="matSortDirection" />

@if (isLoading) {
    <app-loading-spinner />
}
