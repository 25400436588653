<mat-form-field
    appearance="outline"
    floatLabel="auto"
    [subscriptSizing]="subscriptSizing">
    <input matInput
           [formControl]="control"
           [matDatepicker]="picker">
    <mat-label>{{placeholder}}</mat-label>
    <mat-datepicker-toggle
        matIconSuffix
        [for]="picker">
    </mat-datepicker-toggle>
    <mat-datepicker #picker />
    @if (control?.invalid) {
        <mat-error>{{ getErrorMessage() }}</mat-error>
    }
</mat-form-field>
