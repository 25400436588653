import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Client } from '@models/client/client.model';
import { Facility } from '@models/facility.model';
import { PaginatedSearch } from '@models/search/paginated-search.model';
import { Practice } from '@models/practice.model';
import { Provider } from '@models/provider/provider.model';
import { SearchSortParams, SearchParams, UserSearchParams } from '@models/search/search-params.model';
import { Shard } from '@models/shard.model';
import { User } from '@models/user/user.model';

import { ApiAccessService } from '@services/api-access.service';
import { ModelMapperService } from '@services/utility/model-mapper.service';

@Injectable()
export class SearchService {
  private controller: string = 'search';

  constructor(
    private apiService: ApiAccessService,
    private modelMapperService: ModelMapperService,
  ) { }

  //#region Search

  public clientSearchSort(params: Map<string, string>): Observable<PaginatedSearch> {
    return this.searchSorting('ClientSearchLookup', params, Client);
  }

  public facilitySearch(params: SearchSortParams): Observable<PaginatedSearch> {
    return this.searchSort('FacilitySearchLookup', params, Facility);
  }

  public practiceSearch(params: SearchSortParams): Observable<PaginatedSearch> {
    return this.searchSort('PracticeSearch', params, Practice);
  }

  public providerSearch(params: SearchSortParams): Observable<PaginatedSearch> {
    return this.searchSort('ProviderSearch', params, Provider);
  }

  public shardSearchSort(params: SearchSortParams): Observable<PaginatedSearch> {
    return this.searchSort('ShardSearchLookup', params, Shard);
  }

  public search(method: string, params: SearchParams, type?: any): Observable<any[]> {
    let parameterMap = new Map<string, string>();
    parameterMap.set('param', JSON.stringify(params));

    return this.apiService.getWithParams(this.controller, method, parameterMap, type);
  }

  public searchSort(method: string, params: SearchSortParams, type?: any | nil): Observable<any> {
    let parameterMap = new Map<string, string>();
    parameterMap.set('param', JSON.stringify(params));

    return this.searchSorting(method, parameterMap, type);
  }

  public searchSorting(method: string, parameterMap: Map<string, string>, type?: any | nil): Observable<any> {
    return this.apiService.getWithParams(this.controller, method, parameterMap, PaginatedSearch).pipe(
      map((result: PaginatedSearch) => {
        // We don't know the results' type when mapping PaginatedSearch. Map to type here
        if (type)
          result.results = result.results.map((value: any) => {
            return this.modelMapperService.mapResult(value, type);
          });

        return result;
      }),
    );
  }

  public userSearch(params: UserSearchParams): Observable<User[]> {
    return this.search('UserSearch', params, User);
  }

  public userSearchSort(params: SearchSortParams): Observable<PaginatedSearch> {
    return this.searchSort('UserSearchSortLookup', params, User);
  }

  //#endregion Search

  //#region Utility

  public updatePaginator(matPaginator: MatPaginator | nil, result: PaginatedSearch): void {
    if (matPaginator) {
      matPaginator.length = result?.totalResultsCount ?? 0;

      if (result.page) {
        matPaginator.pageIndex = result?.page - 1;
      }
    }
  }

  public updateSort(matSort: MatSort | nil, params: SearchSortParams): void {
    if (matSort) {
      matSort.direction = params.direction == 'asc' ? 'asc' : 'desc';
      matSort.active = params.sort ?? '';
    }
  }

  //#endregion Utility
}
