import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

import { SideNavItem } from '@models/side-nav-item.model';

import { CloneService } from '@services/utility/clone.service';
import { UIUtilityService } from '@services/utility/ui-utility.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements AfterViewInit, OnDestroy {
  @ViewChild('innerContainer') innerContainer: ElementRef | nil;
  @ViewChild(MatSidenav) matSideNav: MatSidenav | nil;
  @ViewChild(MatSidenav, { read: ElementRef }) matSideNavEl: ElementRef | nil;
  @ViewChildren('sideNavButton', { read: ElementRef }) buttons: QueryList<ElementRef> | nil;

  @Input() containerClass: string = '';
  @Input() isOpened: boolean = true;
  @Input() model: SideNavItem[] = [];
  @Input() navigationDisabled: boolean = false;
  @Input() navigationDisplayed: boolean = true;

  public bottomOverflowMenuItems: SideNavItem[] = [];
  public isOpen: boolean = true;
  public scrollTop: number = 0;
  public topOverflowMenuItems: SideNavItem[] = [];

  private subscription: Subscription = new Subscription();

  constructor(
    private cloneService: CloneService,
    private router: Router,
    private uiUtilityService: UIUtilityService,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setDisplayedItems();
      this.subscribeToScroll();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //#region Events

  @HostListener('window:resize')
  onResize() {
    this.setDisplayedItems();
  }

  public onClick(item: SideNavItem): void {
    item.onClick ? item.onClick() : this.router.navigateByUrl(item.routerLink ?? '');
  }

  public onIsActiveChange(event: boolean, index: number): void {
    if (event) {
      this.scroll(index);
    }
  }

  //#endregion Events

  //#region Scroll

  private subscribeToScroll(): void {
    if (this.innerContainer)
      this.subscription.add(
        fromEvent<Event>(this.innerContainer.nativeElement, 'scroll').subscribe((e: Event) => {
          this.scrollTop = (e.target as any)['scrollTop'];
          this.setDisplayedItems();
        }),
      );
  }

  private scroll(scrollIndex: number): void {
    const currentStep: ElementRef | nil = this.buttons?.filter((button, index) => index === scrollIndex).pop();
    if (currentStep && this.buttons) {
      if (scrollIndex == 0) {
        this.uiUtilityService.scrollTo(this.innerContainer?.nativeElement, 0, 0);
      } else if (scrollIndex == this.buttons.length - 1) {
        this.uiUtilityService.scrollTo(
          this.innerContainer?.nativeElement,
          this.innerContainer?.nativeElement.scrollHeight + currentStep.nativeElement.offsetHeight,
          0,
        );
      } else {
        const scrollHeight: number = this.innerContainer?.nativeElement.scrollHeight;
        const scrollTo: number =
          scrollHeight - (this.buttons.length - scrollIndex) * currentStep.nativeElement.offsetHeight;
        this.uiUtilityService.scrollTo(
          this.innerContainer?.nativeElement,
          scrollTo - currentStep.nativeElement.offsetHeight / 2,
          0,
        );
      }
    }
  }

  //#endregion Scroll

  //#region Utility

  private buildOverflowMenu(items: SideNavItem[]): SideNavItem[] {
    let list: SideNavItem[] = [];
    if (items) {
      items
        .filter((item) => {
          return item.isDisplayed();
        })
        .forEach((item) => {
          list.push(this.buildSideNavItem(item));
        });
    }
    return list;
  }

  private buildSideNavItem(item: SideNavItem): SideNavItem {
    let newItem: SideNavItem = this.cloneService.deepClone(item);
    newItem.onClick = () => this.onClick(item);

    return newItem;
  }

  private setDisplayedItems(): void {
    // Heights
    const buttonHeight: number = this.buttons?.first ? this.buttons.first.nativeElement.offsetHeight : 0;
    const innerHeight: number = this.innerContainer ? this.innerContainer.nativeElement.offsetHeight : 0;
    const scrollHeight: number = this.innerContainer ? this.innerContainer.nativeElement.scrollHeight : 0;

    // Max buttons
    const maxButtons: number = Math.floor(innerHeight / buttonHeight);

    // Top
    const startIndex: number = this.scrollTop == 0 ? 0 : Math.ceil(this.scrollTop / buttonHeight);
    this.topOverflowMenuItems = this.buildOverflowMenu(
      this.model.filter((step, index) => {
        return index < startIndex;
      }),
    );

    // Bottom
    const endIndex: number =
      this.scrollTop + innerHeight == scrollHeight ? this.model.length : maxButtons + startIndex - 1;
    this.bottomOverflowMenuItems = this.buildOverflowMenu(
      this.model.filter((step, index) => {
        return index > endIndex;
      }),
    );
  }

  //#endregion Utility
}
