<mat-form-field
    appearance="outline"
    floatLabel="auto"
    subscriptSizing="dynamic">
    <mat-label>MM/YYYY – MM/YYYY</mat-label>
    <mat-date-range-input>
        <input
            matStartDate
            [formControl]="startFormControl"
            placeholder="Start date" />
        <input
            matEndDate
            [formControl]="endFormControl"
            placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle
        matIconSuffix
        [matMenuTriggerFor]="menu"></mat-datepicker-toggle>
</mat-form-field>

<mat-menu
    #menu="matMenu"
    class="filter-menu row">
    <mat-calendar
        class="disable-period-click"
        [startView]="matCalendarView"
        #startCalendar
        [selected]="startFormControl.value"
        [maxDate]="maxStartDate"
        (click)="$event.stopPropagation()"
        (viewChanged)="onViewChanged(startCalendar)"
        (monthSelected)="onMonthSelected($event, true)">
    </mat-calendar>
    <mat-divider vertical></mat-divider>
    <mat-calendar
        class="disable-period-click"
        [startView]="matCalendarView"
        #endCalendar
        [selected]="endFormControl.value"
        [maxDate]="maxDate"
        [minDate]="minEndDate"
        (click)="$event.stopPropagation()"
        (viewChanged)="onViewChanged(endCalendar)"
        (monthSelected)="onMonthSelected($event, false)">
    </mat-calendar>
</mat-menu>
