import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { IconButtonBase } from '@models/icon/icon-button.model';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent extends IconButtonBase {
  @Input() blurOnClick: boolean = true;

  @ViewChild('button', { static: false, read: ElementRef }) button!: ElementRef;

  constructor(private elementRef: ElementRef) {
    super();
  }

  public override onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.disabled) {
      this.button.nativeElement.blur();
      this.elementRef.nativeElement.blur();
      if (this.blurOnClick) {
        setTimeout(() => {
          this.click.emit(event);
        }, 0);
      } else {
        this.click.emit(event);
      }
    }
  }
}
