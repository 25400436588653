import { Subject, Observable } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { filter, first } from 'rxjs/operators';

import { ImagePickerComponent } from '@components/image/image-picker/image-picker.component';

export class ImagePickerOverlayRef {
  public component: ImagePickerComponent | nil;

  private _afterClosed: Subject<Blob | nil> = new Subject();

  constructor(public overlayRef: OverlayRef) {}

  public afterClosed(): Observable<Blob | nil> {
    return this._afterClosed.asObservable();
  }

  public close(blob: Blob | nil): void {
    if (this.component) {
      this.component.animationStateChanged$
        .pipe(
          filter((event) => event.phaseName == 'done' && event.toState == 'fadeOut'),
          first(),
        )
        .subscribe(() => {
          this.overlayRef.dispose();
          this._afterClosed.next(blob);
          this._afterClosed.complete();
        });
      this.component.startExitAnimation();
    }
  }
}
