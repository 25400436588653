import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControl], [formControlName]',
})
export class NativeElementInjectorDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
  ) {}

  ngAfterViewInit(): void {
    if (this.ngControl.control) {
      (<any>this.ngControl.control).nativeElement = this.el.nativeElement;
    }
  }
}
