import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Observable, Subscription, tap } from 'rxjs';

import { PaginatedSearchComponent } from '@components/search/paginated-search/paginated-search.component';

import { PaginatedSearch } from '@models/search/paginated-search.model';
import { User } from '@models/user/user.model';

@Directive({})
export abstract class PaginatedSearchControlBase implements AfterViewInit, OnDestroy {
  @Input() label: string = 'Search';
  @Input() pageIndex: number | nil = null;
  @Input() pageSize: number = 10;
  @Input() sort: string | nil = null;
  @Input() sortDirection: SortDirection = 'asc';

  @Output() optionSelected: EventEmitter<User | nil> = new EventEmitter<User | nil>();

  @ViewChild(PaginatedSearchComponent) paginatedSearchComponent!: PaginatedSearchComponent;

  private subscription: Subscription = new Subscription();

  ngAfterViewInit(): void {
    this.subscription.add(
      this.paginatedSearchComponent.optionSelected
        .pipe(
          tap((value: any) => {
            this.optionSelected.emit(value);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public abstract displayWithFunction(value: any): string;
  public abstract searchFunction(searchTerm: string): Observable<PaginatedSearch>;

  public clear(emitEvent: boolean = true): void {
    this.paginatedSearchComponent.onClearClick(emitEvent);
  }
}
