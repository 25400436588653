<mat-form-field
    subscriptSizing="dynamic"
    appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        type="text"
        title="search"
        [placeholder]="placeholder"
        matInput
        [formControl]="formControl"
        [matAutocomplete]="autoComplete" />
    @if (isSearching) {
        <app-progress-indicator
            matTextSuffix
            [isLoading]="isSearching" />
    } @else if (!formControl.value) {
        <app-icon
            matTextSuffix
            icon="magnifyingGlass"
            fontSize="x-small" />
    } @else if (!isSearching) {
        <app-icon-button
            matTextSuffix
            icon="close"
            fontSize="x-small"
            tooltip="Clear"
            (click)="onClearClick()" />
    }
    <mat-autocomplete
        #autoComplete="matAutocomplete"
        [displayWith]="displayWithFunction"
        (optionSelected)="onOptionSelected($event)">
        @for (data of datasource | async; track data) {
            <mat-option [value]="data">{{ displayWithFunction(data) }}</mat-option>
        }
        @if (showResultCount) {
            <mat-divider></mat-divider>
            <div class="totals-container">{{ paginatedResultsCount }} of {{ totalResultsCount }}</div>
        }
    </mat-autocomplete>
</mat-form-field>
