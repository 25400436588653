import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private domSanitizer: DomSanitizer) {}

  public mimeTypeFromDataUrl(dataUrl: string | nil): string | nil {
    return dataUrl?.split(',')[0].split(':')[1].split(';')[0];
  }

  public blobToDataUrl(blob: Blob, callback: (dataUrl: string) => void) {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: ProgressEvent<FileReader>) => callback(<string>e.target?.result);
    fileReader.readAsDataURL(blob);
  }

  public sanitizeURL(source: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(source);
  }

  public sanitizeResourceURL(source: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(source);
  }
}
