export enum EntityType {
  None = 'None',
  Client = 'Client',
  CMMSCuresApplication = 'CMMS Cures Application',
  CMMSCuresBulkApplication = 'CMMS Cures Bulk Application',
  Shard = 'Shard',
  User = 'User',
  MonthEnd = 'MonthEnd',
  Practice = 'Practice',
  Provider = 'Provider',
  MeasureConfiguration = 'Measure Configuration'
}
