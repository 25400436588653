<div class="tree-container">
    <mat-checkbox
        [checked]="isAllSelected"
        [indeterminate]="isAllPartialSelected"
        [disabled]="allChecked"
        color="primary"
        (change)="onAllToggle()"
        >Select All</mat-checkbox
    >
    <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl">
        <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodeToggle
            matTreeNodePadding>
            <button
                mat-icon-button
                disabled></button>
            <mat-checkbox
                class="checklist-leaf-node"
                [checked]="checklistSelection.isSelected(node) || allChecked"
                [disabled]="allChecked"
                color="primary"
                (change)="onLeafSelectionToggle(node)"
                >{{ node.item }}
                @if (node.extra) {
                    <span>({{ node.extra }})</span>
                }
            </mat-checkbox>
        </mat-tree-node>

        <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding>
            <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.item">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
                </mat-icon>
            </button>
            <mat-checkbox
                [checked]="descendantsAllSelected(node) || allChecked"
                [indeterminate]="!allChecked && descendantsPartiallySelected(node)"
                [disabled]="allChecked"
                color="primary"
                (change)="onSelectionToggle(node)">
                {{ node.item }}
                @if (node.extra) {
                    <span> ({{ node.extra }})</span>
                }
            </mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>

<div class="selection-container">
    @for (client of currentClientPracticeSelection; track client) {
        <div>
            <span>{{ client.displayName }}</span>
            @if (!client.hasSinglePractice) {
                <span>{{ practiceDisplay(client) }}</span>
            }
        </div>
    }
</div>
