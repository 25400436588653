import { Component, Input, OnInit } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Validators, FormControl, FormGroup } from '@angular/forms';

import { Address, CityStateLookup, Country, StateProvince, ZipCodeLookup } from '@models/address.model';
import { CountryEnum } from '@enums/country.enum';

import { AddressService } from '@services/address.service';

import { FormControlBase } from '@models/abstract/form-control-base.model';
import { CustomPatternValidator, PatternErrorMessageType } from '@validators/custom-pattern.validator';
import { ValidationMessageService } from '@services/validation-message.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends FormControlBase implements OnInit {
  @Input() showLine2: boolean = true;
  @Input() required: boolean = true;
  @Input() address: Address | nil = new Address();

  public countries: Country[] = [];
  public stateProvinces: StateProvince[] = [];
  public stateProvinceLabel: string = 'State';
  public countryLabel: string = 'Country';
  public postalCodeLabel: string = 'Zip code';
  public countyLabel: string = 'County';

  private previousState: any = null;
  private previousCountry: any = null;
  private requiredState: boolean = true;

  constructor(
    private addressService: AddressService,
    private customPatternValidator: CustomPatternValidator,
    validationMessageService: ValidationMessageService,
  ) {
    super(validationMessageService);
  }

  ngOnInit() {
    this.countries = this.addressService.countries;

    if (this.required && this.control) this.control.setValidators([Validators.required]);

    this.buildForm();
  }

  public clear() {
    this.control.controls.line1.setValue(null);
    this.control.controls.line2.setValue(null);
    this.control.controls.postalCode.setValue(null);
    this.control.controls.city.setValue(null);
    this.control.controls.stateProvince.setValue(null);

    this.updateRequiredState();
  }

  private get isUSAAddress(): boolean {
    const selectedCountryID: number | nil = this.control.controls.country.value?.countryID;

    return selectedCountryID == CountryEnum.US;
  }

  private get isCanadianAddress(): boolean {
    const selectedCountryID: number | nil = this.control.controls.country.value?.countryID;

    return selectedCountryID == CountryEnum.CA;
  }

  private getCountryFromCode(code: string | nil): Country | nil {
    if (code) {
      return this.countries.find((c) => c.code == code);
    } else if (this.required) {
      return this.countries[0];
    } else return null;
  }

  private getStateProvinceFromCode(code: string): StateProvince | nil {
    return this.stateProvinces.find((s) => s.code == code);
  }

  //#endregion Getters/Setters

  //#region Form

  private buildForm() {
    (this.control as FormGroup).addControl(
      'line1',
      new FormControl<string | nil>(
        { value: this.address?.line1, disabled: this.control.disabled },
        this.required ? Validators.required : null,
      ),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'line2',
      new FormControl<string | nil>({ value: this.address?.line2, disabled: this.control.disabled }), { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'city',
      new FormControl<string | nil>(
        { value: this.address?.city, disabled: this.control.disabled },
        this.required ? Validators.required : null,
      ),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'stateProvince',
      new FormControl<string | nil>({ value: null, disabled: this.control.disabled }, this.required ? Validators.required : null), { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'postalCode',
      new FormControl<string | nil>(
        { value: this.address?.postalCode, disabled: this.control.disabled },
        this.required ? Validators.required : null,
      ),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'country',
      new FormControl<any>(
        { value: this.getCountryFromCode(this.address?.countryCode), disabled: this.control.disabled },
        this.required ? Validators.required : null,
      ),
      { emitEvent: false }
    );

    this.updateRequiredState();
    this.countryChange();
    if (this.address?.stateProvinceCode) {
      this.control.controls.stateProvince.setValue(this.getStateProvinceFromCode(this.address.stateProvinceCode), { emitEvent: false });
      this.previousState = this.control.controls.stateProvince.value;
    }

    if (this.address?.countryCode) {
      this.control.controls.country.setValue(this.getCountryFromCode(this.address.countryCode), { emitEvent: false });
      this.previousCountry = this.control.controls.country.value;
    }

    this.subscription.add(
      this.control.controls.postalCode.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
        const maskedPostalCode: string =
          value?.length <= 5 || value?.length === 10 // No need to mask
            ? value
            : this.maskPostalCode(value);

        this.control.controls.postalCode.setValue(maskedPostalCode, { emitEvent: false });

        this.cityStateLookup();
        this.updateRequiredState();
      }),
    );

    this.subscription.add(
      this.control.controls.stateProvince.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((stateProvince: StateProvince) => {
          //if data is cleared and is required mark the control as touched
          if (this.requiredState && !stateProvince && this.previousState) {
            this.control.controls.stateProvince.markAsTouched();
          }
          this.previousState = stateProvince;

          this.stateProvinceChange();
          this.updateRequiredState();
        }),
    );

    this.subscription.add(
      this.control.controls.country.valueChanges.pipe(distinctUntilChanged()).subscribe((country: Country) => {
        if (this.requiredState && !country && this.previousCountry) {
          this.control.controls.country.markAsTouched();
        }
        this.previousCountry = country;

        this.countryChange();
        this.updateRequiredState();
      }),
    );
  }

  //#endregion Form

  //#region Events

  public countryChange(resetStateProvince: boolean = true) {
    const country: Country = this.control.controls.country.value;

    let isUSA: boolean = this.isUSAAddress;

    this.stateProvinceLabel = isUSA ? 'State' : 'Province';
    this.postalCodeLabel = isUSA ? 'Zip code' : 'Postal code';
    this.stateProvinces = this.addressService.getStateProvincesFromCountry(country?.countryID);
    this.updatePostalCodeValidators();

    if (resetStateProvince) {
      this.control.controls.stateProvince.setValue(null, { emitEvent: false });
    }

    this.stateProvinceChange();
  }

  public stateProvinceChange() {
    this.zipCodeLookup();
  }

  public maskPostalCode(value: string): string | nil {
    if (!value) return;

    let maskedPostalCode = '';

    if (this.isCanadianAddress) {
      maskedPostalCode =
        value.length === 4 && !value.includes(' ') // Similar logic as above, but with a space, and after the user types a 4th character in the field.
          ? value.slice(0, 3) + ' ' + value.slice(3, value.length)
          : value;
    } else {
      //default to US based format
      maskedPostalCode =
        value.length >= 6 && !value.includes('-') // Insert a dash, once the user has typed a sixth character in the field. If the user backspaces to the dash, do not insert another dash
          ? value.slice(0, 5) + '-' + value.slice(5, value.length)
          : value;
    }

    return maskedPostalCode;
  }

  public stateCompareWith(object1: StateProvince, object2: StateProvince): boolean {
    return object1 != null && object2 != null && object1.stateProvinceID == object2.stateProvinceID;
  }

  public countryCompareWith(object1: Country, object2: Country): boolean {
    return object1 != null && object2 != null && object1.countryID == object2.countryID;
  }
  //#endregion Events

  //#region Lookup

  public cityStateLookup() {
    if (
      this.isUSAAddress &&
      this.control.controls.postalCode.value &&
      !this.control.controls.city.value &&
      !this.control.controls.stateProvince.value
    ) {
      this.addressService.cityStateLookup(this.control.controls.postalCode.value).subscribe((result) => {
        let cityStateResult: CityStateLookup = result as CityStateLookup;

        if (cityStateResult.city) {
          this.control.controls.city.setValue(cityStateResult.city);

          if (this.address) {
            this.address.city = cityStateResult.city;
          }
        }

        if (cityStateResult.state) {
          const state: StateProvince | nil = this.stateProvinces.find(
            (s) => s.code.toLocaleUpperCase() == cityStateResult.state.toLocaleUpperCase(),
          );
          if (state != null) {
            this.control.controls.stateProvince.setValue(state);
            this.stateProvinceChange();
          }
        }
      });
    }
  }

  public zipCodeLookup() {
    let selectedStateProvinceLocal: StateProvince | nil = this.control.controls.stateProvince.value;

    // If this is a user address and we don't have a postal code and all the data
    // needed to lookup the postal code is present.
    if (
      this.isUSAAddress &&
      !this.control.controls.postalCode.value &&
      this.control.controls.line1.value &&
      this.control.controls.city.value &&
      selectedStateProvinceLocal
    ) {
      this.addressService
        .zipCodeLookup(
          this.control.controls.line1.value,
          this.control.controls.line2.value,
          this.control.controls.city.value,
          selectedStateProvinceLocal.code,
        )
        .subscribe((result) => {
          let postalResult: ZipCodeLookup = result as ZipCodeLookup;

          if (postalResult.zip5) {
            if (postalResult.zip4) {
              this.control.controls.postalCode.setValue(postalResult.zip5 + '-' + postalResult.zip4);
            } else {
              this.control.controls.postalCode.setValue(postalResult.zip5);
            }

            if (this.address) {
              this.address.postalCode = this.control.controls.postalCode.value;
            }
          }
        });
    }
  }

  //#endregion Lookup

  //#region Update

  private updateCountryValidators() {
    this.control.controls.country.setValidators([Validators.required]);
    this.control.controls.country.markAsTouched();
    this.control.controls.country.updateValueAndValidity();
  }

  private updatePostalCodeValidators() {
    if (this.requiredState && this.control.controls.country.value) {
      this.control.controls.postalCode.setValidators(
        Validators.compose([
          Validators.required,
          this.customPatternValidator
            .validate(this.control.controls.country.value.postalCodeRegex, PatternErrorMessageType.format)
            .bind(this),
        ]),
      );

      if (this.control.controls.postalCode.value) this.control.controls.postalCode.markAsTouched();
      this.control.controls.postalCode.updateValueAndValidity({ emitEvent: false });
    } else if (this.requiredState) {
      this.control.controls.postalCode.setValidators(Validators.compose([Validators.required]));

      if (this.control.controls.postalCode.value) this.control.controls.postalCode.markAsTouched();

      this.control.controls.postalCode.updateValueAndValidity({ emitEvent: false });
    }
  }

  private updateRequiredState() {
    if (
      !this.required &&
      this.requiredState &&
      (!this.control.controls.line1.value || this.control.controls.line1.value.length == 0) &&
      (!this.control.controls.line2.value || this.control.controls.line2.value.length == 0) &&
      (!this.control.controls.city.value || this.control.controls.city.value.length == 0) &&
      !this.control.controls.stateProvince.value &&
      (!this.control.controls.postalCode.value || this.control.controls.postalCode.value.length == 0)
    ) {
      //address is not required, fields are currently marked as required, and no fields have any data then turn off validators
      this.requiredState = false;

      this.control.controls.line1.clearValidators();
      this.control.controls.city.clearValidators();
      this.control.controls.postalCode.clearValidators();
      this.control.controls.stateProvince.clearValidators();
      this.control.controls.country.clearValidators();

      this.control.controls.line1.updateValueAndValidity();
      this.control.controls.city.updateValueAndValidity();
      this.control.controls.postalCode.updateValueAndValidity();
      this.control.controls.stateProvince.updateValueAndValidity();
      this.control.controls.country.updateValueAndValidity();
    } else if (
      !this.requiredState &&
      ((this.control.controls.line1.value && this.control.controls.line1.value.length > 0) ||
        (this.control.controls.line2.value && this.control.controls.line2.value.length > 0) ||
        (this.control.controls.city.value && this.control.controls.city.value.length > 0) ||
        (this.control.controls.stateProvince.value && this.control.controls.stateProvince.value.stateProvinceID > -1) ||
        (this.control.controls.postalCode.value && this.control.controls.postalCode.value.length > 0))
    ) {
      //validators are turned off and we have data in at least one address field then turn on validators
      this.requiredState = true;

      this.control.controls.line1.setValidators([Validators.required]);
      this.control.controls.city.setValidators([Validators.required]);
      this.updatePostalCodeValidators();
      this.control.controls.stateProvince.setValidators([Validators.required]);

      this.control.controls.line1.updateValueAndValidity();
      this.control.controls.city.updateValueAndValidity();
      this.control.controls.stateProvince.updateValueAndValidity();
      this.updateCountryValidators();
    }
  }

  //#endregion Update
}
