import { SortDirection } from '@angular/material/sort';
import { UserPermissionLevel } from '@models/user/user-role.model';

export class SearchParams {
  direction: SortDirection | nil;
  searchFilter: string | nil;

  constructor(direction: SortDirection | nil, searchFilter: string | nil) {
    this.direction = direction;
    this.searchFilter = searchFilter;
  }
}

export class SearchSortParams extends SearchParams {
  public sort: string | nil;
  public page: number | nil;
  public pageSize: number | nil;

  constructor(
    sort: string | nil = '',
    direction: SortDirection | nil = 'asc',
    pageIndex: number | nil = 1,
    pageSize: number = 10,
    filter: string | nil = '',
  ) {
    super(direction, filter);
    this.sort = sort;
    this.page = pageIndex;
    this.pageSize = pageSize;
  }
}

export class ActiveSearchSortParams extends SearchSortParams {
  public isActive: boolean = true;

  constructor(
    sort: string | nil = '',
    direction: SortDirection | nil = 'asc',
    pageIndex: number | nil = 1,
    pageSize: number = 10,
    filter: string | nil = '',
    isActive: boolean = true,
  ) {
    super(sort, direction, pageIndex, pageSize, filter);
    this.isActive = isActive;
  }
}

export class DateSearchSortParams extends SearchSortParams {
  start?: Date | nil;
  end?: Date | nil;

  constructor(
    sort: string | nil = '',
    direction: SortDirection | nil = 'asc',
    pageIndex: number | nil = 1,
    pageSize: number = 10,
    filter: string | nil = '',
    start: Date | nil,
    end: Date | nil,
  ) {
    super(sort, direction, pageIndex, pageSize, filter);
    this.start = start;
    this.end = end;
  }
}

export class ReportingSearchSortParams extends DateSearchSortParams {
  clientID?: number | nil;
  practiceGUID?: string | nil;

  constructor(
    sort: string | nil = '',
    direction: SortDirection | nil = 'asc',
    pageIndex: number | nil = 1,
    pageSize: number = 10,
    start: Date | nil,
    end: Date | nil,
    practiceGUID: string | nil = null,
    clientID: number | nil = null,
  ) {
    super(sort, direction, pageIndex, pageSize, null, start, end);
    this.clientID = clientID;
    this.practiceGUID = practiceGUID;
  }
}

export class UserSearchParams extends SearchParams {
  public includedUserID: string | nil;
  public includeLinked: boolean = true;
  public systemType: boolean = false;
  constructor(
    direction: SortDirection = 'asc',
    filter: string = '',
    includeLinked: boolean = true,
    includedUserID: string | nil,
    isSystemType: boolean = false,
  ) {
    super(direction, filter);
    this.includeLinked = includeLinked;
    this.includedUserID = includedUserID;
    this.systemType = isSystemType;
  }
}

export class UserSearchSortParams extends SearchSortParams {
  public systemType: boolean = false;
  public excludedUserID: string | nil = null;
  public isRegistered: boolean | nil = null;
  public statusFilters: string[] = [];
  public userTypes: string[] = [];

  constructor(
    sort: string | nil = '',
    direction: SortDirection | nil = 'asc',
    pageIndex: number = 0,
    pageSize: number = 10,
    filter: string = '',
    isSystemType: boolean = false,
    excludedUserID: string | nil = null,
    statusFilters: string[] = [],
    userTypes: string[] = [],
  ) {
    super(sort, direction, pageIndex, pageSize, filter);
    this.systemType = isSystemType;
    this.excludedUserID = excludedUserID;
    this.statusFilters = statusFilters.map((statusFilter: string) => statusFilter.toLowerCase());
    this.userTypes = userTypes.map((userType: string) => {
      switch (userType) {
        case 'Client':
          return 'client';
        case 'Administrator':
          return 'stiadmin';
        case 'Standard':
          return 'stistandard';
        case 'Master':
          return 'stimaster';
        case 'System':
          return 'system';
        default:
          return '';
      }
    });
  }
}
