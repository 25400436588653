import { Component, Input } from '@angular/core';

import { IconType } from '@models/icon/icon.model';

@Component({
  selector: 'app-watermark-icon',
  templateUrl: './watermark-icon.component.html',
  styleUrls: ['./watermark-icon.component.scss'],
})
export class WatermarkIconComponent {
  @Input() icon: IconType = 'unknown';
  @Input() text: string = '';

  constructor() {}
}
