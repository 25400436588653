import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';

import { FormControlBase } from '@models/abstract/form-control-base.model';
import { Person } from '@models/person.model';

import { RegularExpressionService } from '@services/utility/regular-expression.service';
import { ValidationMessageService } from '@services/validation-message.service';

import { CustomPatternValidator, PatternErrorMessageType } from '@validators/custom-pattern.validator';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
})
export class NameComponent extends FormControlBase implements OnInit {
  @Input() includeMiddle: boolean = true;
  @Input() includeSuffix: boolean = true;
  @Input() person: Person | nil = new Person();
  @Input() required: boolean = true;

  constructor(
    validationMessageService: ValidationMessageService,
    private customPatternValidator: CustomPatternValidator,
    private regularExpressionService: RegularExpressionService,
  ) {
    super(validationMessageService);
  }

  ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    (this.control as FormGroup).addControl(
      'first',
      new FormControl<string | nil>({ value: this.person?.firstName, disabled: this.control.disabled }, [
        Validators.required,
        Validators.maxLength(35),
        this.customPatternValidator
          .validate(this.regularExpressionService.namePattern, PatternErrorMessageType.characters)
          .bind(this),
      ]),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'middle',
      new FormControl<string | nil>({ value: this.person?.middleName, disabled: this.control.disabled }, [
        Validators.maxLength(35),
        this.customPatternValidator
          .validate(this.regularExpressionService.namePattern, PatternErrorMessageType.characters)
          .bind(this),
      ]),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'last',
      new FormControl<string | nil>({ value: this.person?.lastName, disabled: this.control.disabled }, [
        Validators.required,
        Validators.maxLength(60),
        this.customPatternValidator
          .validate(this.regularExpressionService.namePattern, PatternErrorMessageType.characters)
          .bind(this),
      ]),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'suffix',
      new FormControl<string | nil>({ value: this.person?.suffix, disabled: this.control.disabled }, [
        Validators.maxLength(10),
        this.customPatternValidator
          .validate(this.regularExpressionService.nameNoSpacePattern, PatternErrorMessageType.characters)
          .bind(this),
      ]),
      { emitEvent: false }
    );
  }
}
