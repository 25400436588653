export class ApiError {
  messages: ApiErrorMessage[] = [];
  statusCode: number | nil;
}

export class ApiErrorMessage {
  details: string | nil;
  typeCode: ApiErrorTypeCode | nil;
  message: string | nil;
  title: string | nil;
}

export enum ApiErrorTypeCode {
  UnhandleException = 1,
  HandledError = 2,
  Warning = 3,
  Information = 4,
}