import { InjectionToken } from '@angular/core';

export interface ImagePickerOverlayData {
  canBackdropClickClose?: boolean;
  canEscapeClose?: boolean;
  area?: string;
}

export const IMAGE_PICKER_OVERLAY_DATA_TOKEN = new InjectionToken<ImagePickerOverlayData>(
  'IMAGE_PICKER_OVERLAY_DATA_TOKEN',
);
