import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';

import { EntityType } from '@enums/entity-type.enum';

import { AccessType } from '@models/access-type.model';
import { ClientPracticeView } from '@models/client/client.model';
import { User } from '@models/user/user.model';
import { UserClientGroup } from '@models/client/user-client-group.model';
import { UserPermissionEnum, UserPermissionLevelEnum } from '@enums/user-permission.enum';
import { UserPermissionLevel } from '@models/user/user-role.model';
import { UserSearchSortParams } from '@models/search/search-params.model';

import { ApiAccessService } from '@services/api-access.service';
import { ClientConnectionService } from '@services/client-connection.service';
import { DialogService } from '@services/dialog.service';
import { EntityLockService } from '@services/entity-lock.service';
import { SearchService } from '@services/search.service';
import { UserSecurityService } from '@services/user-security.service';

@Injectable()
export class UserAdministrationService {
  private controller: string = 'UserAdministration';
  private userClientGroupController: string = 'UserClientGroup';

  public selectedUser: User | nil;
  public typeList: AccessType[] = [];

  constructor(
    private apiAccessService: ApiAccessService,
    private clientService: ClientConnectionService,
    private dialogService: DialogService,
    private entityLockService: EntityLockService,
    private searchService: SearchService,
    private security: UserSecurityService,
  ) {}

  //#region Get

  public getClientsForUser(userID: string): Observable<ClientPracticeView[]> {
    return this.apiAccessService.get(this.controller, 'GetClientsForUser', userID, false, ClientPracticeView);
  }

  public getClientUserGroups(): Observable<UserClientGroup[]> {
    return this.apiAccessService.get(this.userClientGroupController, 'GetForUser', null, false, UserClientGroup);
  }

  public getUser(userID: string): Observable<User | nil> {
    return this.apiAccessService.get(
      this.controller,
      this.clientService.isConnected() ? 'GetUser' : 'GetConsoleUser',
      userID,
      false,
      User,
    );
  }

  public getUserRoles(): Observable<AccessType[]> {
    let map: Map<string, string> = new Map();

    return this.apiAccessService.get(this.controller, 'types', null, false, AccessType).pipe(
      tap((x: AccessType[]) => {
        this.typeList = x.sort((a, b) => a.displayName.localeCompare(b.displayName));
      }),
    );
  }

  public loadUsersByPractice(): Observable<User[]> {
    return this.apiAccessService.get(this.controller, 'GetRegisteredUsersByPractice', null, false, User);
  }

  //#endregion

  //#region Save

  public saveUserClientGroup(userClientGroup: UserClientGroup): Observable<UserClientGroup> {
    const action: string = userClientGroup.userClientGroupID <= 0 ? 'Add' : 'Edit';

    return this.apiAccessService.postJSON(this.userClientGroupController, action, userClientGroup, UserClientGroup);
  }

  public saveUser(user: User | nil, resendEmail: boolean = false): Observable<boolean> {
    const values = new Map();
    const action: string = 'EditUser';

    values.set('userVMJSON', JSON.stringify(user));
    values.set('sendEmail', resendEmail);

    return this.apiAccessService.post(this.controller, action, values);
  }

  //#endregion

  //#region Delete

  public deleteUserClientGroup(userClientGroup: UserClientGroup): Observable<any> {
    return this.apiAccessService.delete(
      this.userClientGroupController,
      'Remove',
      userClientGroup.userClientGroupID.toString(),
    );
  }

  public deleteUser(user: User): Observable<any> {
    const username = user.firstname + ' ' + user.lastname + (user.isRegistered ? ' (' + user.username + ')' : '');
    const locks = this.entityLockService.getLocks(EntityType.User, user.id);
    const message = locks.length > 0 ? this.entityLockService.getLockMessage(EntityType.User, [user.id]) : '';

    return this.dialogService.confirmationPrompt('delete', 'user', [username], message).afterClosed$.pipe(
      takeWhile((value: boolean | nil) => value === true),
      switchMap(() => {
        return this.apiAccessService.delete(this.controller, 'DeleteUser', user.userID ?? '');
      }),
    );
  }

  //#endregion

  public canChangeUserPermissions(): boolean {
    if (!this.clientService.isConnected()) {
      return this.security.hasPermission(UserPermissionEnum.consoleUsers, UserPermissionLevelEnum.full);
    }

    return false;
  }

  public checkNameAvailability(name: string): Observable<boolean> {
    return this.apiAccessService.get(this.userClientGroupController, 'CheckNameAvailability', name);
  }

  public search(
    sort: string | nil,
    direction: SortDirection | nil,
    pageIndex: number | nil,
    pageSize: number | nil,
    filter: string | nil,
    excludedUserID: string | nil,
    statusFilters: string[] = [],
    typeFilters: string[] = [],
  ): Observable<any> {
    const params: UserSearchSortParams = new UserSearchSortParams(
      sort,
      direction,
      pageIndex ?? 1,
      pageSize ?? 10,
      filter ?? '',
      false,
      excludedUserID,
      statusFilters,
      typeFilters,
    );

    return this.searchService.userSearchSort(params);
  }

  public sendInvite(user: User): Observable<boolean> {
    const values = new Map();

    values.set('userVMJSON', JSON.stringify(user));

    return this.apiAccessService.post(this.controller, 'RegisterUser', values);
  }

  public toggleUserLock(user: User): Observable<any> {
    let map = new Map();
    map.set('userID', user.userID);

    return this.apiAccessService.post(this.controller, 'ToggleUserLock', map);
  }
}
