<mat-form-field
    appearance="outline"
    [matMenuTriggerFor]="menu"
    subscriptSizing="dynamic">
    @if (showLabel) {
        <mat-label>Practice</mat-label>
    }
    <input
        matInput
        [value]="inputValue"
        type="text"
        title="Client Practice selection"
        #tooltip="matTooltip"
        matTooltip="{{ tooltipValue }}"
        matTooltipClass="mat-tooltip-fulllength multiline-tooltip"
        appTooltipShowIfTruncated />
    <div
        matSuffix
        class="suffix">
        <app-icon icon="caretDown" />
    </div>
</mat-form-field>

<mat-menu
    #menu="matMenu"
    class="filter-menu">
    @for (client of clients; track client) {
        @if (client.hasSinglePractice) {
            <div mat-menu-item>
                <mat-checkbox
                    [checked]="checklistSelection.isSelected(client)"
                    color="primary"
                    (click)="$event.stopPropagation(); onClick(client)"
                    >{{ client.displayName }}</mat-checkbox
                >
            </div>
        } @else {
            <div
                mat-menu-item
                [class.padded-menu-item]="!canSelectMultiplePractices"
                [matMenuTriggerFor]="practiceMenu"
                [matMenuTriggerData]="{ practices: client.practices, client: client }">
                @if (canSelectMultiplePractices) {
                    <mat-checkbox
                        [disabled]="client.practices.length === 0"
                        [checked]="descendantsAllSelected(client)"
                        [indeterminate]="descendantsPartiallySelected(client)"
                        color="primary"
                        (click)="$event.stopPropagation(); onClick(client)">
                        {{ client.displayName }}
                    </mat-checkbox>
                } @else {
                    @if (!canSelectMultiplePractices) {
                        <span>{{ client.displayName }}</span>
                    }
                }
            </div>
        }
    }
</mat-menu>

<mat-menu #practiceMenu="matMenu">
    <ng-template
        matMenuContent
        let-client="client"
        let-practices="practices">
        @for (practice of practices; track practice) {
            <div mat-menu-item>
                <mat-checkbox
                    [checked]="checklistSelection.isSelected(practice)"
                    color="primary"
                    (click)="$event.stopPropagation(); onPracticeChange(practice, client)">
                    {{ practice.description }} {{ practice.hdsNumber ? "(" + practice.hdsNumber + ")" : "" }}
                </mat-checkbox>
            </div>
        }
    </ng-template>
</mat-menu>
