import { Component, Input, ViewChild } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

import { PaginatedGridComponent } from '@components/paginated-grid/paginated-grid.component';

import { PaginatedGridColumnGroup } from '@models/search/paginated-grid-column.model';
import { PaginatedSearch } from '@models/search/paginated-search.model';

@Component({
  selector: 'app-paginated-grid-container',
  templateUrl: './paginated-grid-container.component.html',
  styleUrls: ['./paginated-grid-container.component.scss'],
})
export class PaginatedGridContainerComponent {
  @Input() isLoading: boolean = false;
  @Input() actionOptions: any[] = [];
  @Input({ required: true }) columnGroup!: PaginatedGridColumnGroup;
  @Input() dataSource: PaginatedSearch | nil;
  @Input() matSortActive: string = '';
  @Input() matSortDirection: SortDirection = 'asc';

  @ViewChild(PaginatedGridComponent) paginatedGridComponent!: PaginatedGridComponent;
}
