import { Directive, HostListener, ContentChildren, QueryList, AfterContentInit, OnDestroy, Input } from '@angular/core';
import { ChangeTrackerService, ChangeTrackerToken } from '@services/change-tracker.service';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[changeTracker]',
})
export class TrackableDirective implements AfterContentInit, OnDestroy {
  @ContentChildren(MatSelect, { descendants: true }) selects: QueryList<MatSelect> | nil;

  @Input('changeTracker') changeTrackerToken: ChangeTrackerToken | nil;

  private subscription: Subscription = new Subscription();

  public constructor(private changeTracker: ChangeTrackerService) {}

  ngAfterContentInit() {
    this.selects?.map((select: MatSelect) => {
      this.subscription.add(
        select.selectionChange.subscribe(() => {
          if (this.changeTrackerToken) this.changeTracker.markAsChanged(this.changeTrackerToken);
        }),
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('input') input() {
    if (this.changeTrackerToken) this.changeTracker.markAsChanged(this.changeTrackerToken);
  }
}
