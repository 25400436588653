import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent {
  @Input({ required: true }) endFormControl!: FormControl<Date | nil>;
  @Input({ required: true }) startFormControl!: FormControl<Date | nil>;
}
