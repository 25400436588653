<app-icon-button
    icon="plug"
    [fontSize]="fontSize"
    tooltip="Connect to {{ client.displayName }}"
    [matMenuTriggerFor]="menu" />

<mat-menu #menu="matMenu">
    @for (item of sideNavItems; track item) {
        <div
            mat-menu-item
            (click)="onConnectClick(item.routerLink)">
            <app-icon [icon]="item.icon" />
            <span>{{ item.label }}</span>
        </div>
    }
</mat-menu>
