import { TitleCasePipe } from '@angular/common';
import { Component, Input, ViewChild, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TooltipPosition } from '@angular/material/tooltip';

import { FormControlBase } from '@models/abstract/form-control-base.model';

import { ValidationMessageService } from '@services/validation-message.service';

export class SelectGroup {
  groupLabel: string = '';
  disabled: boolean = false;
  options: any[] = [];
}

@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss'],
})
export class DropDownListComponent extends FormControlBase {
  @Input() blankOption: boolean = false;
  @Input() datasource: any[] | nil = [];
  @Input() displayProperty: string = '';
  @Input() public displayPipe: PipeTransform | nil = new TitleCasePipe();
  @Input() externalCompareWith: ((object1: any, object2: any) => boolean) | nil; // Required when comparing complex objects
  @Input() multilineTooltip: boolean = false;
  @Input() multiselect: boolean = false;
  @Input() prefixIconType: any | nil;
  @Input() showClear: boolean = false;
  @Input() tooltipPosition: TooltipPosition = 'after';
  @Input() tooltipProperty: string = '';

  @ViewChild('selectElement', { static: false }) selectElement: MatSelect | nil;

  public selectedData: string | nil;

  constructor(validationMessageService: ValidationMessageService) {
    super(validationMessageService);
  }

  //#region Get

  public get compareWith(): any {
    return this.externalCompareWith ?? this.internalCompareWith;
  }

  public get hasOverflow(): boolean {
    if (this.selectElement) {
      const overflowed: boolean =
        this.selectElement._elementRef.nativeElement.firstElementChild.firstElementChild.scrollWidth >
        this.selectElement._elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth;
      return overflowed;
    }

    return false;
  }

  //#endregion

  //#region Events

  public clear() {
    this.control?.setValue(null);
  }

  public selectionChanged(event: MatSelectChange): void {
    this.selectedData = event.source.triggerValue;
  }

  //#endregion

  //#region Utility

  public internalCompareWith(object1: any, object2: any): boolean {
    return object1 && object2 && object1 === object2;
  }

  public isOptionGroup(data: any): boolean {
    if (data.hasOwnProperty('disabled') && data.hasOwnProperty('groupLabel') && data.hasOwnProperty('options'))
      return true;
    else return false;
  }

  public IsRequired(): boolean {
    if (this.control?.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  //#endregion
}
