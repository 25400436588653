import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatBadgePosition, MatBadgeSize } from '@angular/material/badge';

import { FilterGroup } from '@models/filter-group.model';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnChanges {
  @Input() badgeSize: MatBadgeSize = "small";
  @Input() badgePosition: MatBadgePosition = "before";
  @Input() disabled: boolean = false;
  @Input() model: FilterGroup[] = [];
  @Input() showText: boolean = true;

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  public filterLength: number | nil;
  public tooltip: string = "";

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.model) {
      this.filterLength = null;
      this.tooltip = "";

      this.model.forEach(filterGroup => {
        if (filterGroup.filters.length) {
          if (!this.filterLength) {
            this.filterLength = 0;
          }
          this.filterLength += filterGroup.filters.length;
        }

        this.tooltip = `${this.tooltip}${this.setTooltip(filterGroup.groupTitle, filterGroup.filters)}`;
      });
    }
  }

  public onClick(): void {
    this.clicked.emit();
  }

  private setTooltip(title: string, filters: string[]): string {
    let tooltip: string = "";
    filters.forEach((filter, index) => {
      if (index == 0)
        tooltip = `${title} \n ${filter}${filters.length == 1 ? '\n\n' : ''}`;
      else if (index < filters.length - 1)
        tooltip = `${tooltip} | ${filter}`;
      else
        tooltip = `${tooltip} | ${filter} \n\n`;
    });
    return tooltip;
  }
}
