import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { UserPermissionEnum, UserPermissionLevelEnum } from '@enums/user-permission.enum';

import { PaginatedSearch } from '@models/search/paginated-search.model';
import { PaginatedSearchControlBase } from '@models/abstract/paginated-search-control-base.model';
import { User } from '@models/user/user.model';

import { AuthService } from '@services/auth.service';
import { UserAdministrationService } from '@services/user-administration.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrl: './user-search.component.scss',
})
export class UserSearchComponent extends PaginatedSearchControlBase {
  @Input() statusFilters: string[] = [];
  @Input() typeFilters: string[] = ['Standard'];
  @Input() userPermissionEnum: UserPermissionEnum | nil;
  @Input() userPermissionLevelEnum: UserPermissionLevelEnum | nil;

  constructor(
    private authService: AuthService,
    public userAdministrationService: UserAdministrationService,
  ) {
    super();
  }

  displayWithFunction(value: User | nil): string {
    return value ? value.dropDownDisplay : '';
  }

  searchFunction(searchTerm: string): Observable<PaginatedSearch> {
    return this.userAdministrationService.search(
      this.sort,
      this.sortDirection,
      this.pageIndex,
      this.pageSize,
      searchTerm,
      this.authService.getSubjectID(),
      this.statusFilters,
      this.typeFilters,
    );
  }
}
