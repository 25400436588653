export class StringUtility {
  private constructor() {}

  public static isWhiteSpace(value: string): boolean {
    if (value) {
      let regex: RegExp = new RegExp('\\S'); // Contains something other than whitespace.

      if (!regex.test(value)) {
        return true;
      }
    }

    return false;
  }

  public static hasLeadingWhiteSpace(value: string): boolean {
    if (value && value.length && value[0] == ' ') {
      return true;
    }

    return false;
  }

  public static hasTrailingWhiteSpace(value: string): boolean {
    if (value && value.length && value[value.length - 1] == ' ') {
      return true;
    }

    return false;
  }

  public static isFirstLetterCapitalized(value: string): boolean {
    if (value && value[0] && value[0] == value[0].toUpperCase()) {
      return true;
    }

    return false;
  }

  public static capitalizeFirstLetter(value: string): string | nil {
    if (value && value[0]) {
      const firstUpper: string = value[0].toUpperCase();

      return `${firstUpper}${value.length > 1 ? value.slice(1) : ''}`;
    }

    return null;
  }
}
