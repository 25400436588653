import { Component, Inject, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import {
  ImagePickerOverlayData,
  IMAGE_PICKER_OVERLAY_DATA_TOKEN,
} from '@services/image/tokens/image-picker-overlay-token';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject, Subscription } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ImageCrop } from '@components/image/image-crop/image-crop.component';

import { ImagePickerOverlayRef } from '@services/image/remotes/image-picker-overlay-ref';
import { ImageService } from '@services/image/image.service';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeIn', style({ opacity: 1 })),
      state('fadeOut', style({ opacity: 0 })),
      transition('* => fadeIn', animate('400ms')),
    ]),
  ],
})
export class ImagePickerComponent implements OnInit, OnDestroy {
  @ViewChild(ImageCrop) imageCroppingComponent: ImageCrop | nil;

  private readonly animationStateChanged: Subject<AnimationEvent> = new Subject();
  public readonly animationStateChanged$: Observable<AnimationEvent> = this.animationStateChanged.asObservable();

  public fadeAnimationState: 'fadeIn' | 'fadeOut' = 'fadeIn';
  public pageViewMode: 'picker' | 'cropper' = 'picker';
  public imageChangedEvent: any = '';
  public headingText: string | nil;
  public error: string | nil;
  public dropzoneActive: boolean = false;
  public selectedImageSource: 'Webcam' | 'Upload' = 'Upload';
  public selectedImageDataUrl: string | nil;
  public webcamTrigger: Subject<void> = new Subject();

  private subscription: Subscription = new Subscription();
  private errorInvalidFileType: string = 'Invalid file format. Valid file extensions include: .png, .gif, .jpg, .tiff';

  constructor(
    private overlayRef: ImagePickerOverlayRef,
    @Inject(IMAGE_PICKER_OVERLAY_DATA_TOKEN) private data: ImagePickerOverlayData,
    private imageService: ImageService,
  ) {
    if (this.data.canBackdropClickClose) {
      this.overlayRef.overlayRef.backdropClick().subscribe((_) => this.close(null));
    }
  }

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then((devices: MediaDeviceInfo[]) => {
      if (devices && devices.length > 0) {
        this.selectedImageSource = 'Webcam';
      }
    });
    this.showPicker();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public get webcamTriggerObservable(): Observable<void> {
    return this.webcamTrigger.asObservable();
  }

  //#region Events

  public onAnimationStart(e: AnimationEvent): void {
    this.animationStateChanged.next(e);
  }

  public onAnimationEnd(e: AnimationEvent): void {
    this.animationStateChanged.next(e);
  }

  public onBackClicked(): void {
    if (this.pageViewMode == 'picker') {
      this.close(null);
    } else if (this.pageViewMode == 'cropper') {
      this.showPicker();
    }
  }

  public onDropzoneDragOver(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.dropzoneActive = true;
  }

  public onDropzoneDragLeave(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.dropzoneActive = false;
  }

  public onDropzoneDrop(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.dropzoneActive = false;

    this.imageChangedEvent = e;

    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file: File = e.dataTransfer.files[0];

      if (this.isFileImageType(file)) {
        this.imageService.blobToDataUrl(file, (dataUrl: string) => {
          this.selectedImageDataUrl = dataUrl;
          this.showCropper();
        });
      } else {
        this.error = this.errorInvalidFileType;
      }
    }
  }

  public onFilesSelected(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const files: FileList = target.files as FileList;

    this.imageChangedEvent = event;

    if (files && files.length > 0) {
      const file: File = files[0];

      if (this.isFileImageType(file)) {
        this.imageService.blobToDataUrl(file, (dataUrl: string) => {
          this.selectedImageDataUrl = dataUrl;
          this.showCropper();
        });
      } else {
        this.error = this.errorInvalidFileType;
      }
    }
  }

  public onImageSourceChange(e: MatRadioChange): void {
    this.error = undefined;
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onKeydownEscape(e: KeyboardEvent): void {
    if (this.data.canEscapeClose) {
      this.close(null);
    }
  }

  public onSaveClicked(): void {
    const mimeType: string | nil = this.imageService.mimeTypeFromDataUrl(this.selectedImageDataUrl);

    if (this.imageCroppingComponent) {
      this.imageCroppingComponent.imageCropper.crop('blob')?.then((value: ImageCroppedEvent) => this.close(value.blob));
    }
  }

  public onWebcamHandleImage(image: WebcamImage): void {
    this.selectedImageDataUrl = image.imageAsDataUrl;
    this.showCropper();
  }

  public onWebcamInitError(e: WebcamInitError): void {
    if (e.mediaStreamError && e.mediaStreamError.name == 'NotAllowedError') {
      this.error = `Please allow ${window.location.hostname} to use your camera`;
    } else {
      this.error = e.message;
    }
  }

  public onWebcamTriggerSnap(): void {
    this.webcamTrigger.next();
  }

  //#endregion

  //#region Utility

  private close(blob: Blob | nil): void {
    this.overlayRef.close(blob);
  }

  private isFileImageType(file: File): boolean {
    if (file && file.type && file.type.toLowerCase().startsWith('image/')) {
      return true;
    }

    return false;
  }

  private showCropper(): void {
    this.pageViewMode = 'cropper';
    this.headingText = 'Crop Photo';
    this.error = undefined;
  }

  private showPicker(): void {
    this.pageViewMode = 'picker';
    this.headingText = `${this.data.area} Photo`;
  }

  public startExitAnimation(): void {
    this.fadeAnimationState = 'fadeOut';
  }

  //#endregion
}
