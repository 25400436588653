<mat-form-field
    appearance="outline"
    [floatLabel]="floatLabel"
    [subscriptSizing]="subscriptSizing">
    <!--Label/Placeholder-->
    <mat-label>{{ placeholder }}</mat-label>

    <!--Input-->
    <input
        #inputElement
        matInput
        [formControl]="control"
        type="text"
        [required]="isRequired()"
        [min]="min"
        [max]="max"
        matTooltip="{{ control?.value }}"
        appTooltipShowIfTruncated
        (keypress)="onKeyPress($event, true)"
        (change)="onChange($event)"
        (focus)="onFocus($event)" />
    <span matTextPrefix>$&nbsp;</span>
    <!--Suffix-->
    @if (control.enabled) {
        <div
            matSuffix
            class="mat-form-field-infix input-control-suffix">
            <!--Clear (button)-->
            <app-icon-button
                [class.hidden]="!control?.value"
                icon="close"
                fontSize="small"
                [color]="null"
                aria-label="Clear"
                [tabbable]="false"
                tooltip="Clear"
                (click)="onClear()" />
        </div>
    }
    <!--Error message-->
    @if (control?.invalid) {
        <mat-error>{{ getErrorMessage() }}</mat-error>
    }
</mat-form-field>
