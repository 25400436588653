import { Component, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
})
export class ImageCrop {
  @Input() imageURL: any = '';

  @ViewChild(ImageCropperComponent) imageCropper!: ImageCropperComponent;

  croppedImage: any = '';

  constructor(private sanitizer: DomSanitizer) {}

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ?? '');
  }
}
