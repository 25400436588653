import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][appTooltipShowIfTruncated]',
})
export class TooltipShowIfTruncatedDirective {
  @Input() forceShowTooltip: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private matTooltip: MatTooltip,
  ) {}

  @HostListener('mouseenter', ['$event'])
  private onMouseEnter(e: MouseEvent): void {
    const element: any = this.elementRef.nativeElement;
    if (this.forceShowTooltip) {
      this.matTooltip.disabled = false;
    } else {
      if ((element.classList as DOMTokenList).contains('mat-option')) {
        if ((element.firstElementChild.nextElementSibling.classList as DOMTokenList).contains('mat-option-text')) {
          this.matTooltip.disabled =
            element.firstElementChild.nextElementSibling.scrollWidth <=
            element.firstElementChild.nextElementSibling.clientWidth;
        } else if (element.firstElementChild?.firstElementChild?.firstElementChild) {
          this.matTooltip.disabled =
            element.firstElementChild.firstElementChild.firstElementChild.scrollWidth <=
            element.firstElementChild.firstElementChild.firstElementChild.clientWidth;
        } else {
          this.matTooltip.disabled = element.firstElementChild.scrollWidth <= element.firstElementChild.clientWidth;
        }
      } else if ((element.classList as DOMTokenList).contains('mat-select')) {
        this.matTooltip.disabled =
          element.firstElementChild.firstElementChild.scrollWidth <=
          element.firstElementChild.firstElementChild.clientWidth;
      } else {
        this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
      }
    }
  }
}
