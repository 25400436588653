import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removePeriod',
})
export class RemovePeriodPipe implements PipeTransform {
  transform(value: string | nil): string | nil {
    if (value && value.length) {
      value = value.trim();

      if (value[value.length - 1] == '.') {
        value = value.substring(0, value.length - 1);
      }
    }

    return value;
  }
}
