import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { IconBase } from '@models/icon/icon.model';

@Directive({})
export class IconButtonBase extends IconBase {
  @Input() disabled: boolean = false;
  @Input() disableRipple: boolean = false;
  @Input() tabbable: boolean = true;

  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  public onClick(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.disabled) {
      this.click.emit(event);
    }
  }
}
