import { IconType } from '@models/icon/icon.model';

export class SideNavItem {
  public label: string = '';
  public icon: IconType = 'add';
  public isCurrent = () => false;
  public isDisabled: () => boolean = () => false;
  public onClick: (() => any) | nil;
  public routerLink: string = '';
  public isDisplayed = () => false;
  public tooltip: () => string = () => '';
  public useAlternateStyle: boolean = true;
}
