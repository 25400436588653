import { Component, Input } from '@angular/core';

import { ClientPracticeView } from '@models/client/client.model';
import { FontSize } from '@models/icon/icon.model';
import { Practice } from '@models/practice.model';
import { SideNavItem } from '@models/side-nav-item.model';

import { ClientConnectionService } from '@services/client-connection.service';
import { SideNavService } from '@services/sidenav.service';

@Component({
  selector: 'app-client-connect-menu',
  templateUrl: './client-connect-menu.component.html',
  styleUrls: ['./client-connect-menu.component.scss'],
})
export class ClientConnectMenuComponent {
  @Input({ required: true }) client!: ClientPracticeView;
  @Input() fontSize: FontSize = 'medium';
  @Input() practiceGUIDs: string[] = [];

  public sideNavItems: SideNavItem[] = [];

  constructor(
    private clientConnectionService: ClientConnectionService,
    private sideNavService: SideNavService,
  ) {
    this.sideNavItems = this.sideNavService.buildClientSideNavItems();
  }

  public onConnectClick(outlet: string): void {
    if (this.client) {
      const practiceGUIDs: string[] = this.practiceGUIDs.length
        ? this.practiceGUIDs
        : this.client.practices.map((value: Practice) => value.practiceGUID);
      this.clientConnectionService.connect(this.client.clientID, practiceGUIDs, outlet).subscribe();
    }
  }
}
