import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  template:
    '@if (isLoading) {<div [class.large-indicator]="doubleSize" class="loading"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>}',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent {
  @Input() isLoading: boolean = false;
  @Input() doubleSize: boolean = false;
}
