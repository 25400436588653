<mat-form-field
    appearance="outline"
    [floatLabel]="floatLabel"
    [subscriptSizing]="subscriptSizing">
    <!--Prefix-->
    @if (prefixIconConfig) {
        <app-icon
            matPrefix
            [icon]="prefixIconConfig" />
    }

    <!--Label/Placeholder-->
    <mat-label>{{ placeholder }}</mat-label>

    <!--Input-->
    @if (!textarea) {
        <input
            #inputElement
            matInput
            [formControl]="control"
            type="{{ inputType }}"
            [required]="isRequired()"
            [maxlength]="maxlength ? maxlength : ''"
            [min]="min"
            [max]="max"
            matTooltip="{{ control.value }}"
            appTooltipShowIfTruncated
            (keypress)="onKeyPress($event, true)"
            (change)="onChange($event)"
            (blur)="onBlur($event)" />
    } @else {
        <textarea
            #inputElement
            matInput
            [formControl]="control"
            type="{{ inputType }}"
            [maxlength]="maxlength ? maxlength : ''"
            (keypress)="onKeyPress($event, false)"
            (change)="onChange($event)">
        </textarea>
    }

    <!--Suffix-->
    @if (control.enabled) {
        <div
            matSuffix
            class="mat-form-field-infix input-control-suffix">
            <!--Text-->
            @if (suffixText) {
                <span matTextSuffix>{{ suffixText }}</span>
            }
            <!--Progress Indicator-->
            @if (isLoading) {
                <mat-spinner
                    color="primary"
                    mode="indeterminate"
                    diameter="16"></mat-spinner>
            }
            <!--Clear (button)-->
            <app-icon-button
                [class.hidden]="!value"
                icon="close"
                fontSize="small"
                [color]="null"
                aria-label="Clear"
                [tabbable]="false"
                tooltip="Clear"
                (click)="onClear()" />
            <!--Projected content-->
            <ng-content select="[suffix]"></ng-content>
            <!--Icon (button)-->
            @if (suffixIconConfig) {
                <app-icon-button
                    [icon]="suffixIconConfig"
                    fontSize="small"
                    [tabbable]="false"
                    (click)="onClick(); $event.stopPropagation()" />
            }
        </div>
    }

    <!--Error message-->
    @if (control?.invalid) {
        <mat-error>{{ getErrorMessage() }}</mat-error>
    }
</mat-form-field>
