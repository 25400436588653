import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormControlBase } from '@models/abstract/form-control-base.model';
import { IconType } from '@models/icon/icon.model';
import { StringUtility } from '@models/static/string-utility.model';

import { ValidationMessageService } from '@services/validation-message.service';

@Component({
  selector: 'app-clearable-input',
  templateUrl: './clearable-input.component.html',
  styleUrls: ['./clearable-input.component.scss'],
})
export class ClearableInputComponent extends FormControlBase implements OnInit {
  @Input() disableInitialSpace: boolean = true; // Prevents the space character from being entered as the first character ENTERED.
  @Input() disableSpace: boolean = false; // Prevents the space character from being entered at all.
  @Input() enableFirstLetterAutoCapitalization: boolean = true; // Enables auto capitalization for the first letter in text input fields
  @Input() enforcePattern: string | nil;
  @Input() inputType: string = 'text';
  @Input() maxlength: number = 0;
  @Input() max: number | nil;
  @Input() min: number = 0;
  @Input() noLeadingWhiteSpace: boolean = true; // Prevents leading whitespace from being allowed as a value.
  @Input() noTrailingWhiteSpace: boolean = true; // Prevents trailing whitespace from being allowed as a value.
  @Input() prefixIconConfig: IconType | nil = null;
  @Input() suffixIconConfig: IconType | nil = null;
  @Input() suffixText: string | nil;
  @Input() textarea: boolean = false;

  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() suffixClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputElement') input: ElementRef | nil;

  public get value(): any {
    return this.control?.value;
  }

  public set value(value: any) {
    this.control?.setValue(value);
    this.input?.nativeElement.dispatchEvent(new Event('input', { bubbles: true }));
  }

  constructor(validationMessageService: ValidationMessageService) {
    super(validationMessageService);
  }

  ngOnInit() {
    this.subscription.add(
      this.control.valueChanges.subscribe((result: any) => {
        if (this.noLeadingWhiteSpace && StringUtility.hasLeadingWhiteSpace(result)) {
          if (StringUtility.isWhiteSpace(result)) {
            this.control?.setValue(null);
          } else {
            this.control?.setValue(result.trimLeft());
          }

          return;
        }

        if (
          result?.length &&
          this.enableFirstLetterAutoCapitalization &&
          this.inputType == 'text' &&
          !StringUtility.isFirstLetterCapitalized(result)
        ) {
          const fixedResult: string | nil = StringUtility.capitalizeFirstLetter(result);
          const selectionStart: number = this.input?.nativeElement.selectionStart;
          const selectionEnd: number = this.input?.nativeElement.selectionEnd;

          this.control?.setValue(fixedResult);

          if (this.input) {
            this.input.nativeElement.selectionStart = selectionStart;
            this.input.nativeElement.selectionEnd = selectionEnd;
          }

          return;
        }

        if (this.maxlength > 0 && this.inputType == 'number' && result?.length > this.maxlength) {
          this.control.setValue(result.slice(0, result?.length - 1));
          return;
        }
      }),
    );
  }

  //#region Events

  public onBlur(event: FocusEvent): void {
    this.blur.emit(event);
  }

  public onChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = target.value;

    if (this.noTrailingWhiteSpace && StringUtility.hasTrailingWhiteSpace(value)) {
      if (StringUtility.isWhiteSpace(value)) {
        this.control?.setValue(null);
      } else {
        this.control?.setValue(value.trimEnd());
      }
    }
  }

  public onClear(): void {
    this.value = null;
  }

  public onClick(): void {
    this.suffixClick.emit();
  }

  public onKeyPress(event: KeyboardEvent, preventEnter: boolean): void {
    const char: string = event.key;

    if (preventEnter && char == 'Enter') {
      event.preventDefault();
    }

    if (
      (this.disableInitialSpace && char == ' ' && this.value != 0 && !this.value) ||
      (this.disableSpace && char == ' ')
    ) {
      event.preventDefault();
    }

    if (this.enforcePattern) {
      const regEx = new RegExp(this.enforcePattern);
      if (!regEx.test(char)) event.preventDefault();
    }
  }

  //#endregion

  //#region Utility

  public isRequired(): boolean {
    if (this.control?.validator) {
      const validator = this.control.validator({} as AbstractControl);

      if (validator && validator['required']) {
        return true;
      }
    }

    return false;
  }

  //#endregion
}
