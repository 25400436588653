<mat-sidenav-container>
    @if (navigationDisplayed) {
        <mat-sidenav
            [ngClass]="containerClass"
            [class.disabled]="navigationDisabled"
            mode="side"
            [opened]="isOpened"
            autoFocus="false">
            <!--Logo-->
            <ng-content [select]="logo"></ng-content>
            <!--Overflow (Top)-->
            <app-sidenav-hidden-menu
                class="note-section-button"
                [class.hidden]="topOverflowMenuItems.length == 0"
                [model]="topOverflowMenuItems"
                [isDisabled]="navigationDisabled">
            </app-sidenav-hidden-menu>
            <!--Inner-->
            <div
                #innerContainer
                class="sidenav-inner-container"
                [class.disabled]="navigationDisabled">
                @for (item of model; track item; let i = $index) {
                    @if (item.isDisplayed()) {
                        <div
                            #sideNavButton
                            class="sidenav-icon-button"
                            [class.settings-link]="item.label === 'Settings'"
                            [class.disabled]="item.isDisabled()"
                            [class.active]="item.isCurrent()"
                            [matTooltip]="item.tooltip()"
                            matTooltipPosition="after"
                            [routerLink]="[item.routerLink]"
                            routerLinkActive="active"
                            (click)="onClick(item)"
                            (isActiveChange)="onIsActiveChange($event, i)">
                            <app-icon
                                [icon]="item.icon"
                                [color]="null"
                                fontSize="medium" />
                            <span class="sidenav-icon-text">{{ item.label }}</span>
                        </div>
                    }
                }
            </div>
            <!--Overflow (Bottom)-->
            <app-sidenav-hidden-menu
                class="note-section-button"
                [class.hidden]="bottomOverflowMenuItems.length == 0"
                [model]="bottomOverflowMenuItems"
                [isDisabled]="navigationDisabled"
                [isTop]="false">
            </app-sidenav-hidden-menu>
            <!--Action Menu-->
            <ng-content select="[action-menu]"></ng-content>
        </mat-sidenav>
    }

    <!--Content-->
    <mat-sidenav-content [class.opened]="isOpened">
        <ng-content select="[content]"></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
