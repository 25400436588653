<img
    #image
    title="image"
    [src]="croppedImage" />
<image-cropper
    [imageURL]="imageURL"
    [aspectRatio]="1 / 1"
    format="png"
    [containWithinAspectRatio]="true"
    (imageCropped)="onImageCropped($event)">
</image-cropper>
