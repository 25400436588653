import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatBadgePosition, MatBadgeSize } from '@angular/material/badge';
import { ThemePalette } from '@angular/material/core';
import { TooltipPosition } from '@angular/material/tooltip';

export type FontSet =
  | 'fat'
  | 'fas'
  | 'far'
  | 'fal'
  | 'fad'
  | 'fab'
  | 'fa-thin'
  | 'fa-solid'
  | 'fa-regular'
  | 'fa-light'
  | 'fa-duotone'
  | 'fa-brands';
export declare type FontSize =
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | undefined
  | nil;
export type IconType =
  | 'add'
  | 'angleLeft'
  | 'anglesLeft'
  | 'angleRight'
  | 'anglesRight'
  | 'arrowRotateRight'
  | 'arrowUpCircle'
  | 'arrowDownCircle'
  | 'arrowUpRightFromSquare'
  | 'arrowsRotate'
  | 'arrowsDown91'
  | 'arrowsDownAZ'
  | 'arrowsUpDownLeftRight'
  | 'barsSort'
  | 'building'
  | 'buildings'
  | 'calendarDay'
  | 'calendarDays'
  | 'calendarRange'
  | 'calendarWeek'
  | 'camera'
  | 'caretDown'
  | 'caretUp'
  | 'chartBar'
  | 'chartBarSimple'
  | 'chartBarHorizontalSimple'
  | 'chartLine'
  | 'chartPie'
  | 'chartTreeMap'
  | 'check'
  | 'checkCircle'
  | 'checkSquare'
  | 'circle'
  | 'circleQuestion'
  | 'close'
  | 'closeCircle'
  | 'database'
  | 'download'
  | 'downLeftAndUpRightToCenter'
  | 'edit'
  | 'ellipsis'
  | 'ellipsisVertical'
  | 'envelopeOpenDollar'
  | 'error'
  | 'exclamationCircle'
  | 'exclamationTriangle'
  | 'expand'
  | 'eye'
  | 'eyeSlash'
  | 'file'
  | 'fileLines'
  | 'filter'
  | 'grid4'
  | 'hospitals'
  | 'hospitalUser'
  | 'house'
  | 'infoCircle'
  | 'list'
  | 'lock'
  | 'logout'
  | 'magnifyingGlass'
  | 'minusSquare'
  | 'notificationBell'
  | 'objectsColumn'
  | 'play'
  | 'plug'
  | 'print'
  | 'rotateRight'
  | 'save'
  | 'settings'
  | 'shareNodes'
  | 'settings'
  | 'sliders'
  | 'sort'
  | 'square'
  | 'squareDollar'
  | 'trashUndo'
  | 'trashX'
  | 'underConstruction'
  | 'unknown'
  | 'unlock'
  | 'upload'
  | 'upRightAndDownLeftFromCenter'
  | 'userCircle';

export class IconConfig {
  color?: ThemePalette;
  fontIcon?: string;
  fontSet: FontSet = 'fa-solid';
  fontSetAlternate?: FontSet;
  inline?: boolean;
  svgIcon?: string;
  unicode: string = '3f';
  unicodeAlternate?: string;

  public static definitions: { [key in IconType]: IconConfig } = {
    add: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f055' },
    angleLeft: { fontSet: 'far', unicode: 'f104' },
    anglesLeft: { fontSet: 'far', unicode: 'f100' },
    angleRight: { fontSet: 'far', unicode: 'f105' },
    anglesRight: { fontSet: 'far', unicode: 'f101' },
    arrowDownCircle: { fontSet: 'far', unicode: 'f0ab' },
    arrowRotateRight: { fontSet: 'far', unicode: 'f01e' },
    arrowUpCircle: { fontSet: 'far', unicode: 'f0aa' },
    arrowUpRightFromSquare: { fontSet: 'far', unicode: 'f08e' },
    arrowsDownAZ: { fontSet: 'far', unicode: 'f15d' },
    arrowsDown91: { fontSet: 'far', unicode: 'f886' },
    arrowsRotate: { fontSet: 'far', unicode: 'f021' },
    arrowsUpDownLeftRight: { fontSet: 'far', unicode: 'f047' },
    barsSort: { fontSet: 'fas', unicode: 'e0ae' },
    building: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f1ad' },
    buildings: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'e0cc' },
    calendarDay: { fontSet: 'far', unicode: 'f783' },
    calendarDays: { fontSet: 'far', unicode: 'f073' },
    calendarRange: { fontSet: 'far', unicode: 'e0d6' },
    calendarWeek: { fontSet: 'far', unicode: 'f784' },
    camera: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f030' },
    caretDown: { fontSet: 'fas', fontSetAlternate: 'fas', unicode: 'f0d7' },
    caretUp: { fontSet: 'fas', fontSetAlternate: 'fas', unicode: 'f0d8' },
    chartBar: { fontSet: 'far', unicode: 'f080' },
    chartBarSimple: { fontSet: 'far', unicode: 'e473' },
    chartBarHorizontalSimple: { fontSet: 'far', unicode: 'e474' },
    chartLine: { fontSet: 'far', unicode: 'f201' },
    chartPie: { fontSet: 'far', unicode: 'f200' },
    chartTreeMap: { fontSet: 'far', unicode: 'e0ea' },
    check: { fontSet: 'fas', unicode: 'f00c' },
    checkCircle: { fontSet: 'fas', unicode: 'f058' },
    checkSquare: { fontSet: 'far', unicode: 'f14a' },
    circle: { fontSet: 'fas', fontSetAlternate: 'far', unicode: 'f111' },
    circleQuestion: { fontSet: 'far', unicode: 'f059' },
    close: { fontSet: 'fas', unicode: 'f00d' },
    closeCircle: { fontSet: 'far', fontSetAlternate: 'far', unicode: 'f057' },
    database: { fontSet: 'far', unicode: 'f1c0' },
    download: { fontSet: 'far', unicode: 'f019' },
    downLeftAndUpRightToCenter: { fontSet: 'far', unicode: 'f422' },
    edit: { fontSet: 'far', unicode: 'f304' },
    ellipsis: { fontSet: 'fas', unicode: 'f141' },
    ellipsisVertical: { fontSet: 'fas', unicode: 'f142' },
    envelopeOpenDollar: { fontSet: 'fas', unicode: 'f657' },
    error: { fontSet: 'fas', unicode: 'f2ee' },
    exclamationCircle: { fontSet: 'fas', unicode: 'f06a' },
    exclamationTriangle: { fontSet: 'far', unicode: 'f071' },
    expand: { fontSet: 'far', unicode: 'f065' },
    eye: { fontSet: 'fas', unicode: 'f06e' },
    eyeSlash: { fontSet: 'far', unicode: 'f070' },
    file: { fontSet: 'fas', unicode: 'f15b' },
    fileLines: { fontSet: 'far', unicode: 'f15c' },
    filter: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f0b0' },
    grid4: { fontSet: 'far', unicode: 'e198' },
    hospitals: { fontSet: 'far', unicode: 'f80e' },
    hospitalUser: { fontSet: 'far', unicode: 'f80d' },
    house: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f015' },
    infoCircle: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f05a' },
    list: { fontSet: 'far', unicode: 'f03a' },
    lock: { fontSet: 'far', unicode: 'f023' },
    logout: { fontSet: 'far', unicode: 'f2f5' },
    magnifyingGlass: { fontSet: 'far', unicode: 'f002' },
    minusSquare: { fontSet: 'far', unicode: 'f146' },
    notificationBell: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f0f3' },
    objectsColumn: { fontSet: 'far', unicode: 'e3c1' },
    play: { fontSet: "fas", unicode: 'f04b' },
    plug: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f1e6' },
    print: { fontSet: 'far', unicode: 'f02f' },
    rotateRight: { fontSet: 'far', unicode: 'f2f9' },
    save: { fontSet: 'far', unicode: 'f0c7' },
    settings: { fontSet: 'far', fontSetAlternate: 'fas', unicode: 'f013' },
    shareNodes: { fontSet: 'far', unicode: 'f1e0' },
    sliders: { fontSet: 'far', unicode: 'f1de' },
    sort: { fontSet: 'far', unicode: 'f0dc' },
    square: { fontSet: 'fas', unicode: 'f0c8' },
    squareDollar: { fontSet: 'far', unicode: 'f2e9' },
    trashUndo: { fontSet: 'far', unicode: 'f895' },
    trashX: { fontSet: 'far', unicode: 'f1f8' },
    underConstruction: { fontSet: 'far', unicode: 'f85d' },
    unknown: { fontSet: 'far', unicode: '3f' },
    unlock: { fontSet: 'far', unicode: 'f09c' },
    upload: { fontSet: "fas", unicode: 'f093' },
    upRightAndDownLeftFromCenter: { fontSet: 'far', unicode: 'f424' },
    userCircle: { fontSet: 'far', unicode: 'f2bd' },
  };
}

@Directive({})
export class IconBase implements OnChanges {
  // MatBadge
  @Input() badgeColor: ThemePalette = 'warn';
  @Input() badgeLength: number | nil;
  @Input() badgePosition: MatBadgePosition = 'before';
  @Input() badgeSize: MatBadgeSize = 'small';

  // Icon
  @Input() color: ThemePalette | nil = null;
  @Input() fontSet: FontSet | nil = 'fas';
  @Input() fontSize: FontSize = 'medium';
  @Input({ required: true }) icon: IconType = 'unknown';
  @Input() inline: boolean | nil;
  @Input() isResponsive: boolean = false;
  @Input() matTooltipClass: string | nil;
  @Input() matTooltipPosition: TooltipPosition = 'below';
  @Input() svgIcon: string | nil;
  @Input() tooltip: string | nil;
  @Input() type: IconType = 'add';
  @Input() useAlternateIcon: boolean = false;
  @Input() useAlternateStyle: boolean = false;

  public iconConfig!: IconConfig;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.iconConfig = IconConfig.definitions[this.icon];
    }
  }

  public get formattedUnicode(): string {
    return !this.useAlternateIcon
      ? '&#x' + this.iconConfig?.unicode + ';'
      : '&#x' + this.iconConfig?.unicodeAlternate + ';';
  }

  public get iconType(): 'svg' | 'font' | nil {
    if (this.svgIcon) {
      return 'svg';
    } else if (this.iconConfig && this.fontSet) {
      return 'font';
    }

    return undefined;
  }

  public get fontSetType(): 'material' | 'fontawesome' | 'fontawesome-unicode' | nil {
    switch (this.fontSet) {
      case 'fas':
      case 'far':
      case 'fal':
      case 'fat':
      case 'fad':
      case 'fab':
      case 'fa-solid':
      case 'fa-regular':
      case 'fa-light':
      case 'fa-thin':
      case 'fa-duotone':
      case 'fa-brands':
        return 'fontawesome';
      default:
        return undefined;
    }
  }
}