import { Component, Input, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';

import { FormControlBase } from '@models/abstract/form-control-base.model';
import { PhoneNumber } from '@models/phone-number.model';

import { CustomPatternValidator, PatternErrorMessageType } from '@validators/custom-pattern.validator';

import { RegularExpressionService } from '@services/utility/regular-expression.service';
import { ValidationMessageService } from '@services/validation-message.service';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent extends FormControlBase implements OnInit {
  @Input() showExtension: boolean = true;
  @Input() phoneNumber: PhoneNumber | nil = new PhoneNumber();

  public uniqueFormControlID: number = 0;

  constructor(
    private customPatternValidator: CustomPatternValidator,
    private regularExpressionService: RegularExpressionService,
    validationMessageService: ValidationMessageService,
  ) {
    super(validationMessageService);
  }

  public ngOnInit(): void {
    if (!this.label) this.label = 'Phone';

    this.buildForm();
  }

  private getPhoneNumberString(): string | nil {
    let tmpPhone: string | nil =
      this.phoneNumber?.areaCode && this.phoneNumber?.number
        ? this.phoneNumber.areaCode + this.phoneNumber.number
        : null;

    if (tmpPhone) {
      if (tmpPhone.length > 0) {
        tmpPhone = '(' + tmpPhone;
      }

      if (tmpPhone.length > 4) {
        tmpPhone = tmpPhone.substr(0, 4) + ')' + tmpPhone.substr(4);
      }

      if (tmpPhone.length > 5) {
        tmpPhone = tmpPhone.substr(0, 5) + ' ' + tmpPhone.substr(5);
      }

      if (tmpPhone.length > 9) {
        tmpPhone = tmpPhone.substr(0, 9) + '-' + tmpPhone.substr(9);
      }

      if (tmpPhone.length > 14) {
        tmpPhone = tmpPhone.substr(0, 14);
      }
    }

    return tmpPhone;
  }

  private buildForm(): void {
    (this.control as FormGroup).addControl(
      'label',
      new FormControl<string | nil>(
        { value: this.phoneNumber?.label ? this.phoneNumber.label : this.label, disabled: this.control.disabled },
        null,
      ),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'number',
      new FormControl<string | nil>(
        { value: this.getPhoneNumberString(), disabled: this.control.disabled },
        Validators.compose([
          this.customPatternValidator
            .validate(this.regularExpressionService.phoneNumberRegex, PatternErrorMessageType.format)
            .bind(this),
        ]),
      ),
      { emitEvent: false }
    );
    (this.control as FormGroup).addControl(
      'extension',
      new FormControl<string | nil>(
        { value: this.phoneNumber?.extension, disabled: this.control.disabled },
        Validators.maxLength(5),
      ),
      { emitEvent: false }
    );

    this.subscription.add(
      this.control.controls.number.valueChanges.subscribe((val: string | nil) => {
        let newVal: string = val as string;

        if (newVal?.length) {
          let needsFormat: boolean = false;

          // Check to see if the phone number is in the right format.
          for (let i = 0; i < newVal.length; i++) {
            if (
              (i == 0 && newVal[i] != '(') ||
              (((i > 0 && i < 4) || (i > 5 && i < 9) || (i > 9 && i < 14)) && newVal[i].match(/\D/g)) ||
              (i == 4 && newVal[i] != ')') ||
              (i == 5 && newVal[i] != ' ') ||
              (i == 9 && newVal[9] != '-') ||
              newVal.length > 14
            ) {
              needsFormat = true;
              break;
            }
          }

          if (needsFormat) {
            // Replace all non numbers to format.
            newVal = newVal.replace(/\D/g, '');

            // Have to check again because maybe we removed everything.
            // If we do have stuff left over, let's format.
            if (newVal.length > 0) {
              newVal = '(' + newVal;
            }

            if (newVal.length > 4) {
              newVal = newVal.substr(0, 4) + ')' + newVal.substr(4);
            }

            if (newVal.length > 5) {
              newVal = newVal.substr(0, 5) + ' ' + newVal.substr(5);
            }

            if (newVal.length > 9) {
              newVal = newVal.substr(0, 9) + '-' + newVal.substr(9);
            }

            if (newVal.length > 14) {
              newVal = newVal.substr(0, 14);
            }

            this.control.controls.number.setValue(newVal, { emitEvent: false });
          }
        }

        let localPhoneNumber: string | nil = newVal ? newVal.replace(/\D/g, '') : null;
      }),
    );

    this.subscription.add(
      this.control.controls.extension.valueChanges.subscribe((val: string | nil) => {
        let newVal: string = val as string;

        if (newVal?.length) {
          if (newVal.length > 5) {
            newVal = newVal.substr(0, 5);
          }

          if (newVal.match(/\D/g)) {
            this.control.controls.extension.setValue(newVal.replace(/\D/g, ''), { emitEvent: false });
          }
        }
      }),
    );
  }
}
