import {
  Component,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  ViewChildren,
  AfterViewInit,
  QueryList,
  OnDestroy,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { ConfigurableFocusTrapFactory, ConfigurableFocusTrap } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-sheet',
  templateUrl: './side-sheet.component.html',
  styleUrls: ['./side-sheet.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('open', style({ transform: 'translate3d(0, 0, 0)' })),
      state('closed', style({ transform: 'translate3d(100%, 0, 0)' })),
      transition('closed <=> open', animate('400ms ease-in-out')),
    ]),
  ],
})
export class SideSheetComponent implements AfterViewInit, OnDestroy {
  @Input() canClose: boolean = true;
  @Input() focusFirstElement: boolean = false;
  @Input() mode: 'open' | 'closed' = 'closed';

  @Output() closed = new EventEmitter();
  @Output() closeStart = new EventEmitter();
  @Output() saveAndClose = new EventEmitter();
  @Output() slideStart = new EventEmitter();

  @ViewChild(CdkPortal) contentTemplate: CdkPortal | nil;
  @ViewChildren('sheetContainer') private sheetContainer: QueryList<ElementRef> | nil;

  private focusTrap: ConfigurableFocusTrap | nil;
  private overlayRef: OverlayRef | nil;
  private subscription: Subscription = new Subscription();

  constructor(
    private overlay: Overlay,
    private focusTrapFactory: ConfigurableFocusTrapFactory,
  ) {}

  ngAfterViewInit() {
    if (this.sheetContainer && this.focusFirstElement) {
      this.subscription.add(
        this.sheetContainer.changes.subscribe((element) => {
          setTimeout(() => {
            if (!this.focusTrap && this.sheetContainer && this.sheetContainer.length > 0) {
              this.focusTrap = this.focusTrapFactory.create(this.sheetContainer.first.nativeElement);
              this.focusTrap.focusInitialElementWhenReady();
            }
          });
        }),
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    if (this.mode == 'open') {
      this.close();
    }
  }

  public toggle(): void {
    this.mode == 'closed' ? this.open() : this.close();
  }

  public open(): void {
    if (!this.overlayRef || !this.overlayRef.hasAttached()) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
      this.overlayRef.attach(this.contentTemplate);
      this.subscription.add(this.overlayRef.backdropClick().subscribe(() => this.close()));
      this.mode = 'open';
    }
  }

  get isOpen(): boolean {
    return this.mode == 'open';
  }

  //#region Events

  public close(): void {
    this.closeStart.emit();

    if (this.canClose) {
      this.finalizeClose();
    }
  }

  public onSlideFinished(): void {
    if (!this.isOpen) {
      if (this.focusTrap) {
        this.focusTrap.destroy();
        this.focusTrap = null;
      }

      if (this.overlayRef) {
        this.overlayRef.dispose();
      }

      this.closed.emit();
    }
  }

  public onSlideStart(): void {
    if (this.mode == 'open') {
      this.slideStart.emit();
    }
  }

  //#endregion

  //#region Utility

  public finalizeClose(): void {
    this.mode = 'closed';
  }

  private getOverlayConfig(): OverlayConfig {
    const position = this.overlay.position().global().right('0').top('0').bottom('0');
    return new OverlayConfig({
      positionStrategy: position,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
    });
  }

  public waitToggle(): void {
    this.canClose = !this.canClose;
  }

  //#endregion
}
