import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { SideSheetComponent } from '@components/side-sheet/side-sheet.component';

import { RoutedFormComponent } from '@models/abstract/form-component.model';

import { ChangeTrackerService } from '@services/change-tracker.service';

@Component({
  selector: 'app-form-side-sheet',
  templateUrl: './form-side-sheet.component.html',
  styleUrls: ['./form-side-sheet.component.scss'],
})
export class FormSideSheetComponent {
  @ViewChild(SideSheetComponent) sidesheet!: SideSheetComponent;

  @Output() onSaved: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private changeTrackerService: ChangeTrackerService,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation?.extras?.state?.saved) {
        this.onSaved.emit(navigation.extras.state.saved);
      }
    });
  }

  public onActivateOutlet(event: RoutedFormComponent): void {
    this.sidesheet.open();
    event.changeTrackerService.sessionStateChanged$.subscribe(() => {
      this.sidesheet.canClose = !this.changeTrackerService.hasAnyChanges();
    });
  }

  public onDeactivateOutlet(event: RoutedFormComponent): void {
    this.sidesheet.finalizeClose();
  }

  public deactivateRoute(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
  }
}
