<div
    class="overlay-container"
    [@fade]="fadeAnimationState"
    (@fade.start)="onAnimationStart($event)"
    (@fade.done)="onAnimationEnd($event)">
    <!--Header-->
    <mat-divider></mat-divider>

    <div class="body-container">
        <!--Error-->
        <span>{{ error }}</span>

        <!--Picker-->
        @if (pageViewMode == "picker") {
            <mat-radio-group
                [(ngModel)]="selectedImageSource"
                (change)="onImageSourceChange($event)">
                <mat-radio-button
                    value="Webcam"
                    color="primary"
                    >Webcam</mat-radio-button
                >
                <mat-radio-button
                    value="Upload"
                    color="primary"
                    >Upload</mat-radio-button
                >
            </mat-radio-group>
            <!--Webcam-->
            @if (selectedImageSource == "Webcam") {
                <div class="webcam-container">
                    <webcam
                        [width]="400"
                        [height]="300"
                        [trigger]="webcamTriggerObservable"
                        (imageCapture)="onWebcamHandleImage($event)"
                        (initError)="onWebcamInitError($event)"></webcam>
                </div>
                <button
                    type="button"
                    mat-flat-button
                    color="primary"
                    (click)="onWebcamTriggerSnap()">
                    SNAP
                </button>
            }
            <!--Upload-->
            @if (selectedImageSource == "Upload") {
                <div
                    class="dropzone"
                    [class.dropzone-active]="dropzoneActive"
                    (dragover)="onDropzoneDragOver($event)"
                    (dragleave)="onDropzoneDragLeave($event)"
                    (drop)="onDropzoneDrop($event)">
                    <input
                        #fileInput
                        type="file"
                        hidden
                        accept="image/*"
                        (change)="onFilesSelected($event)" />
                    <button
                        type="button"
                        mat-flat-button
                        color="primary"
                        (click)="fileInput.click()">
                        CHOOSE FILE
                    </button>
                    <span>Or drag and drop here</span>
                </div>
            }
        }

        <!--Cropper-->
        @if (pageViewMode == "cropper") {
            <app-image-crop [imageURL]="selectedImageDataUrl" />
        }
    </div>

    <!--Cropper-->
    @if (pageViewMode == "cropper") {
        <mat-divider></mat-divider>
        <div class="button-container">
            <button
                type="button"
                mat-stroked-button
                (click)="onBackClicked()">
                BACK
            </button>
            <button
                type="button"
                mat-flat-button
                color="primary"
                (click)="onSaveClicked()">
                SAVE
            </button>
        </div>
    }
</div>
