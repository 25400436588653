import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';

import { ChangeTrackerDialogComponent } from '@components/dialog/change-tracker-dialog/change-tracker-dialog.component';

import { AuthService } from '@services/auth.service';

export interface ChangeTrackerToken {
  id: number;
  objectName: string; //optional name of the model/component that requested the tracking
  onClose?: () => Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class ChangeTrackerService implements OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  private nextID: number = 0;
  private changeList: ChangeTrackerToken[] = [];
  private readonly sessionStateChanged: Subject<ChangeTrackerToken> = new Subject<ChangeTrackerToken>();

  public readonly sessionStateChanged$: Observable<ChangeTrackerToken> = this.sessionStateChanged.asObservable();

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.subscriptions.add(this.authService.userLogout$.subscribe(() => this.markAllAsUnchanged()));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public showWarningDialog(): Observable<string> {
    const dialogRef = this.dialog.open(ChangeTrackerDialogComponent, { panelClass: 'dialog-standard' });

    return dialogRef.afterClosed();
  }

  public getTrackingSession(requestingObjectName: string, onClose?: () => Observable<boolean>): ChangeTrackerToken {
    const id = this.nextID;
    this.nextID++;

    return {
      id: id,
      objectName: requestingObjectName,
      onClose: onClose,
    };
  }

  public hasChanged(changeTrackerSession: ChangeTrackerToken | nil): boolean {
    if (changeTrackerSession) return this.changeList.findIndex((c) => c.id == changeTrackerSession.id) >= 0;
    else return false;
  }

  public hasAnyChanges(): boolean {
    return this.changeList.length > 0;
  }

  public markAsChanged(changeTrackerToken: ChangeTrackerToken | nil): void {
    if (changeTrackerToken) {
      let index: number = this.changeList.findIndex((c) => c.id == changeTrackerToken.id);
      if (index < 0) {
        this.changeList.push(changeTrackerToken);
      }
      this.sessionStateChanged.next(changeTrackerToken);
    }
  }

  public markAsUnchanged(changeTrackerToken: ChangeTrackerToken | nil): void {
    if (changeTrackerToken) {
      let index: number = this.changeList.findIndex((c) => c.id == changeTrackerToken.id);
      if (index >= 0) {
        this.changeList.splice(index, 1);
        this.sessionStateChanged.next(changeTrackerToken);
      }
    }
  }

  public markObjectAsUnchanged(objectName: string): void {
    if (objectName) {
      let list = this.changeList.filter((c) => c.objectName == objectName);
      for (let session of list) {
        let index: number = this.changeList.findIndex((c) => c.id == session.id);
        if (index >= 0) this.changeList.splice(index, 1);
      }
    }
  }

  public markAllAsUnchanged() {
    this.changeList = [];
  }
}
