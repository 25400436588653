import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorHandlerService } from '@services/error-handler.service';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => this.errorHandlerService.handleHttpError(err)));
  }
}
