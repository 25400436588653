import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, finalize, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { DialogComponent, DialogData } from '@components/dialog/dialog.component';

import { ApiError, ApiErrorMessage, ApiErrorTypeCode } from '@models/api-error.model';

import { DialogRef, DialogService } from '@services/dialog.service';
import { ErrorDialogComponent } from '@components/dialog/error-dialog/error-dialog.component';

const ERROR_MESSAGE_DIALOG_DEFAULT_DATA: DialogData = {
  title: 'Unexpected Error',
  message: 'You have encountered an unexpected error. Please contact STI Customer Support.',
};

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private dialogRef: DialogRef<ErrorDialogComponent, string> | nil = null;

  constructor(private dialogService: DialogService) { }

  public handleHttpError(error: HttpErrorResponse): Observable<any> {
    let innerError: ApiError | nil = null;
    const defaultErrorMessage: string =
      'You have encountered an unexpected error, please try again. If the problem persists, please contact STI Customer Support.';

    if (error?.error instanceof ErrorEvent) {
      innerError = new ApiError();
      innerError.messages.push({
        details: null,
        message: error.error.message,
        typeCode: ApiErrorTypeCode.UnhandleException,
        title: 'Error',
      });
    } else if (error?.error instanceof ProgressEvent) {
      innerError = new ApiError();
      innerError.messages.push({
        details: null,
        message: defaultErrorMessage,
        typeCode: ApiErrorTypeCode.UnhandleException,
        title: 'Error',
      });
    }
    else if (error?.error) innerError = error.error as ApiError;
    else {
      innerError = new ApiError();
      innerError.messages.push({
        details: error.error.message,
        message: defaultErrorMessage,
        typeCode: ApiErrorTypeCode.UnhandleException,
        title: 'Error',
      });
    }

    if (error?.status) innerError.statusCode = error.status;

    let dialogData: DialogData[] = this.createDialogDataForError(innerError);

    if (!this.dialogRef && dialogData && dialogData.length) {
      if (dialogData.length == 1 && dialogData[0].message) {
        this.dialogRef = this.dialogService.openMessageError({
          ...ERROR_MESSAGE_DIALOG_DEFAULT_DATA,
          ...dialogData[0],
        });

        return this.dialogRef!.afterClosed$.pipe(
          finalize(() => (this.dialogRef = null)),
          switchMap(() => throwError(innerError)));
      } else if (dialogData.length > 1) {
        this.dialogRef = this.dialogService.openMultiMessageError(dialogData);

        return this.dialogRef!.afterClosed$.pipe(
          finalize(() => (this.dialogRef = null)),
          switchMap(() => throwError(innerError)));
      }
    }

    return EMPTY;
  }

  private createDialogDataForError(error: ApiError): DialogData[] {
    if (error && error.messages && error.messages.length) {
      let data: DialogData[] = [];

      error.messages.forEach((errorMessage: ApiErrorMessage) => {
        if (errorMessage.typeCode) {
          data.push({
            details: errorMessage.details,
            title: errorMessage.title ?? 'Error',
            message: errorMessage.message ?? '',
          });
        }
      });
      return data;
    }

    return [{}];
  }
}
