import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCalendarView } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment, { Moment } from 'moment';

export const MY_FORMATS = {
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MM/YYYY',
  },
};

@Component({
  selector: 'app-date-range-month',
  templateUrl: './date-range-month.component.html',
  styleUrl: './date-range-month.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateRangeMonthComponent {
  @Input({ required: true }) endFormControl!: FormControl<Date | nil>;
  @Input() maxDate: any = new Date();
  @Input({ required: true }) startFormControl!: FormControl<Date | nil>;

  public matCalendarView: MatCalendarView = 'year';

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public get maxStartDate(): any {
    return moment(this.endFormControl.value ?? this.maxDate);
  }

  public get minEndDate(): any {
    return moment(this.startFormControl.value);
  }

  //#region Events

  public onViewChanged(calendar: any): void {
    if (calendar) {
      calendar.currentView = this.matCalendarView;
    }
    this.changeDetectorRef.reattach();
  }

  public onMonthSelected(event: Moment, isStart: boolean): void {
    const date: Date = new Date(event.year(), event.month(), isStart ? 1 : event.endOf('month').date());
    if (isStart) {
      this.startFormControl.setValue(date);
    } else {
      this.endFormControl.setValue(date);
    }
    this.changeDetectorRef.detach();
  }

  //#endregion
}
