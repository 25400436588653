<mat-form-field
    appearance="outline"
    floatLabel="auto"
    subscriptSizing="dynamic">
    <mat-label>MM/DD/YYYY – MM/DD/YYYY</mat-label>
    <mat-date-range-input [rangePicker]="picker">
        <input
            matStartDate
            [formControl]="startFormControl"
            placeholder="Start Date" />
        <input
            matEndDate
            [formControl]="endFormControl"
            placeholder="End Date" />
    </mat-date-range-input>
    <mat-datepicker-toggle
        matIconSuffix
        [for]="picker">
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
