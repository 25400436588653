import { Directive, Input, OnDestroy } from '@angular/core';
import { FloatLabelType, MatFormFieldAppearance, SubscriptSizing } from '@angular/material/form-field';
import { Subscription } from 'rxjs';

import { ValidationMessageService } from '@services/validation-message.service';

@Directive({})
export abstract class FormControlBase implements OnDestroy {
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input({ required: true }) control!: any;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() isLoading: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() subscriptSizing: SubscriptSizing = 'fixed';

  public subscription: Subscription = new Subscription();

  constructor(public validationMessageService: ValidationMessageService) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //#region Utility

  public getError(control: any): string {
    return this.validationMessageService.getValidationMessage(control.errors) ?? '';
  }

  public getErrorMessage(): string | nil {
    let error: string | nil = '';

    if (this.control.controls && this.control.touched) {
      for (let key in this.control.controls) {
        const control = this.control.controls[key];
        if (control.errors) {
          error = this.getError(control);
        }
      }
    } else if (this.control?.errors && this.control.touched) {
      error = this.getError(this.control);
    }

    return error;
  }

  //#endregion
}
