<span mat-dialog-title>Unsaved Changes</span>
<div mat-dialog-content>
    <span
        >You have unsaved changes. You can save your changes, discard changes and leave, or cancel to continue
        editing.</span
    >
</div>
<div
    class="button-container"
    mat-dialog-actions>
    <button
        mat-stroked-button
        color="primary"
        [mat-dialog-close]="'cancel'"
        cdkFocusInitial>
        CANCEL
    </button>
    <button
        mat-stroked-button
        color="primary"
        [mat-dialog-close]="'discard'">
        DISCARD
    </button>
    <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="'ok'">
        SAVE
    </button>
</div>
