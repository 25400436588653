<button
    #button
    class="icon-button"
    [class.hover-disabled]="disableRipple"
    [class.responsive]="isResponsive"
    [class.x-small]="fontSize == 'x-small'"
    [class.small]="fontSize == 'small'"
    [class.medium]="fontSize == 'medium'"
    [class.inherit-color]="color == null"
    mat-icon-button
    [color]="!disabled ? color : null"
    [disabled]="disabled"
    [disableRipple]="disableRipple"
    [matTooltip]="tooltip ?? ''"
    [matTooltipClass]="matTooltipClass ?? ''"
    [matTooltipPosition]="matTooltipPosition"
    [attr.aria-label]="tooltip"
    [attr.tabindex]="tabbable ? null : -1"
    type="button"
    (click)="onClick($event)">
    @if (iconConfig) {
        <mat-icon
            [fontSet]="useAlternateStyle ? iconConfig.fontSetAlternate! : iconConfig.fontSet!"
            [inline]="inline"
            [innerHTML]="formattedUnicode"
            [matBadge]="badgeLength"
            [matBadgeColor]="badgeColor"
            [matBadgeSize]="badgeSize"
            [matBadgePosition]="badgePosition"
            [matBadgeHidden]="badgeLength == 0"
            aria-hidden="false">
        </mat-icon>
    }
</button>
