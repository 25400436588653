import { Component } from '@angular/core';
import { FormControlBase } from '@models/abstract/form-control-base.model';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent extends FormControlBase {
 
}
