import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'trustURL' })
export class TrustUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
    //
  }

  transform(value: string, isResource: boolean = false): SafeUrl | nil {
    if (value) {
      if (isResource) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      } else if (value.includes('http://') || value.includes('https://')) {
        return this.sanitizer.bypassSecurityTrustUrl(value);
      } else {
        return this.sanitizer.bypassSecurityTrustUrl('//' + value);
      }
    }

    return null;
  }
}
