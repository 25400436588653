@if (data.title) {
    <span mat-dialog-title>{{ data.title }}</span>
} @else {
    <span mat-dialog-title>Unexpected error</span>
}
<div mat-dialog-content>
    <span [innerHtml]="data.message"></span>
    @if (detailsExpanded) {
        <div class="details-container">
            <span>{{ data.details }}</span>
        </div>
    }
</div>
<div
    class="button-container"
    mat-dialog-actions>
    @if (data.details) {
        <button
            mat-flat-button
            (click)="detailsExpanded = !detailsExpanded">
            Details
        </button>
    }
    <button
        mat-flat-button
        [mat-dialog-close]="'ok'">
        OK
    </button>
</div>
