<div class="paginated-table-container">
    @if (dataSource) {
        <table
            mat-table
            matSort
            [matSortActive]="matSortActive"
            [matSortDirection]="matSortDirection"
            multiTemplateDataRows
            matSortDisableClear
            [dataSource]="dataSource.results"
            (matSortChange)="onSortChange()">
            <!--Primary Action-->
            @if (primaryActionColumn) {
                <ng-container matColumnDef="action">
                    <th
                        class="action-column"
                        mat-header-cell
                        *matHeaderCellDef></th>
                    <td
                        mat-cell
                        *matCellDef="let element">
                        @if (primaryActionColumn.isClickable(element)) {
                            <app-icon-button
                                [icon]="primaryActionColumn.icon"
                                [tooltip]="primaryActionColumn.tooltip | titlecase"
                                color="primary"
                                (click)="
                                    primaryActionColumn.rowClick(element);
                                    $event.preventDefault();
                                    $event.stopPropagation()
                                ">
                            </app-icon-button>
                        }
                    </td>
                </ng-container>
            }
            <!--Columns-->
            @for (column of columns; track column) {
                <ng-container [matColumnDef]="column.matColumnDef">
                    <th
                        class="data-column-header"
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        [disabled]="!column.canSort">
                        {{ column.useTitleCase ? (column.description | titlecase) : column.description }}
                        <!--Filters-->
                        @if (column.paginatedGridFilters.length) {
                            <app-icon-button
                                icon="filter"
                                fontSize="x-small"
                                [useAlternateStyle]="column.hasSelectedFilters"
                                [matMenuTriggerFor]="menu"
                                [tooltip]="column.selectedFiltersDisplay"
                                color="primary"
                                (click)="$event.stopPropagation()" />
                            <mat-menu
                                #menu="matMenu"
                                class="filter-menu">
                                @for (paginatedGridFilter of column.paginatedGridFilters; track paginatedGridFilter) {
                                    <div mat-menu-item>
                                        <mat-checkbox
                                            color="primary"
                                            (click)="$event.stopPropagation()"
                                            (change)="onFilterChange(paginatedGridFilter)">
                                            {{ paginatedGridFilter.filter }}
                                        </mat-checkbox>
                                    </div>
                                }
                            </mat-menu>
                        }
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element">
                        <div
                            #tooltip="matTooltip"
                            appTooltipShowIfTruncated
                            [matTooltip]="column.display(element) ?? ''"
                            matTooltipClass="mat-tooltip-fulllength">
                            {{ column.display(element) }}
                        </div>
                    </td>
                </ng-container>
            }
            <!--Action Menu-->
            <ng-container matColumnDef="controls">
                <th
                    class="action-column"
                    mat-header-cell
                    *matHeaderCellDef></th>
                <td
                    mat-cell
                    *matCellDef="let element">
                    <app-action-menu
                        [actionOptions]="actionOptions"
                        [element]="element" />
                </td>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                *matRowDef="let row; columns: displayedColumns"
                [class.selected]="row.isSelected"
                mat-row
                (dblclick)="
                    primaryActionColumn ? primaryActionColumn.rowClick(row) : null;
                    $event.preventDefault();
                    $event.stopPropagation()
                "></tr>
        </table>
    }
</div>

<!--Paginator-->
<mat-paginator
    [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons
    (page)="onSortChange()">
</mat-paginator>
