@if (showText) {
    <app-icon-text-button
        class="filter-button"
        [badgeLength]="filterLength"
        matBadgeColor="warn"
        [badgeSize]="badgeSize"
        badgePosition="before"
        icon="filter"
        label="Filter"
        color="primary"
        [disabled]="disabled"
        [tooltip]="tooltip"
        matTooltipClass="multiline-tooltip"
        (click)="onClick(); $event.stopPropagation()">
    </app-icon-text-button>
} @else {
    <app-icon-button
        class="filter-button"
        [badgeLength]="filterLength"
        matBadgeColor="warn"
        [badgeSize]="badgeSize"
        badgePosition="before"
        icon="filter"
        [disabled]="disabled"
        [tooltip]="tooltip"
        matTooltipClass="multiline-tooltip"
        (click)="onClick(); $event.stopPropagation()">
    </app-icon-button>
}
