<form
    #formDirective="ngForm"
    [formGroup]="control"
    autocomplete="off"
    class="form-row">
    <app-clearable-input
        [control]="control.controls.first"
        class="form-col {{ includeSuffix && includeMiddle ? 'col-4' : 'col-6' }}"
        placeholder="First name"
        [maxlength]="35" />

    @if (includeMiddle) {
        <app-clearable-input
            [control]="control.controls.middle"
            class="form-col col-2"
            placeholder="Middle name"
            [maxlength]="35" />
    }

    <app-clearable-input
        [control]="control.controls.last"
        class="form-col {{ includeSuffix && includeMiddle ? 'col-4' : 'col-6' }}"
        placeholder="Last name"
        [maxlength]="60" />

    @if (includeSuffix) {
        <app-clearable-input
            [control]="control.controls.suffix"
            class="form-col col-2"
            placeholder="Suffix"
            [maxlength]="10" />
    }
</form>
