<!--Icon/Menu trigger-->
<div
    [class.visible]="actionMenuTrigger.menuOpen"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ element: element, actionGroups: actionOptions }"
    #actionMenuTrigger="matMenuTrigger">
    @if (!menuText && !submenu) {
        <app-icon-button
            [class.visible]="actionMenuTrigger.menuOpen"
            [color]="color"
            [disabled]="disabled"
            aria-label="Options"
            [icon]="icon"
            [fontSize]="fontSize"
            [tooltip]="tooltip"
            [blurOnClick]="false"
            [disableRipple]="disableRipple"
            [useAlternateStyle]="useAlternateStyle"
            (click)="onOpenClick($event)" />
    }
</div>

<!--Menu-->
<mat-menu
    class="action-menu"
    #menu="matMenu"
    [overlapTrigger]="false"
    [yPosition]="yPosition"
    (closed)="onMenuClosed()">
    @for (actionOption of actionOptions; track actionOption) {
        <div
            mat-menu-item
            [disabled]="!actionOption.isDisabledCallback(element)"
            [matTooltip]="getTooltip(actionOption)"
            matTooltipPosition="after"
            matTooltipClass="multiline-tooltip-explicit mat-tooltip-fulllength"
            (click)="!actionOption.isDisabledCallback(element) ? null : actionOption.onClickCallback(element)">
            <!--Icon-->
            @if (actionOption.iconCallback != null) {
                <app-icon [icon]="actionOption.iconCallback(element)" />
            }
            <!--Text-->
            <span>{{ actionOption.labelCallback(element) }}</span>
        </div>
    }
</mat-menu>
