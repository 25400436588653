import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { Subscription, tap } from 'rxjs';

import { ClientPracticeView } from '@models/client/client.model';
import { Practice } from '@models/practice.model';

import { CloneService } from '@services/utility/clone.service';
import { SessionContextService } from '@services/session-context.service';

@Component({
  selector: 'app-client-practice-select',
  templateUrl: './client-practice-select.component.html',
  styleUrls: ['./client-practice-select.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
  ],
})
export class ClientPracticeSelectComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input({ required: true }) control!: AbstractControl;
  @Input() canSelectMultiplePractices: boolean = true;
  @Input() showLabel: boolean = false;
  @Input() autoSelectFirstClient: boolean = true;

  private subscription: Subscription = new Subscription();

  public checklistSelection = new SelectionModel<any>(true);
  public clients: ClientPracticeView[] = [];
  public inputValue: string = '';
  public tooltipValue: string = '';

  constructor(
    private cloneService: CloneService,
    private sessionContextService: SessionContextService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.sessionContextService.clients$
        .pipe(tap((value: ClientPracticeView[]) => (this.clients = this.cloneService.deepClone(value))))
        .subscribe(),
    );
  }

  ngAfterContentInit(): void {
    let client: ClientPracticeView | nil;

    if (!this.control.value && this.autoSelectFirstClient) {
      client = this.clients[0];
      let practiceViews: Practice[] = client.practices.filter((value: Practice) =>
        this.control.value.practices.map((practice: Practice) => practice.practiceGUID).includes(value.practiceGUID),
      );
      practiceViews.forEach((value: Practice) => {
        this.checklistSelection.select(value);
      });
    } else {
      client = this.clients.find((value: ClientPracticeView) => value.clientID === this.control.value.clientID)!;
    }
    this.checklistSelection.select(client);

    if (client?.practices.length) {
      if (this.control.value) {
        let practiceViews: Practice[] = client.practices.filter((value: Practice) =>
          this.control.value.practices.map((practice: Practice) => practice.practiceGUID).includes(value.practiceGUID),
        );
        practiceViews.forEach((value: Practice) => {
          this.checklistSelection.select(value);
        });
      } else {
        this.checklistSelection.select(client.practices[0]);
      }
    }

    this.onToggle();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //#region Get

  private get selectedClient(): ClientPracticeView {
    const client: ClientPracticeView = this.checklistSelection.selected.find(
      (value: any) => value instanceof ClientPracticeView,
    );
    return client;
  }

  private get selectedPractices(): Practice[] {
    return this.checklistSelection.selected.filter((value: any) => value instanceof Practice);
  }

  //#endregion

  //#region Events

  public onClick(client: ClientPracticeView): void {
    if (client.clientID != this.selectedClient?.clientID) {
      this.checklistSelection.clear();
      this.checklistSelection.select(client);
    }

    client.practices?.forEach((practice: any) => {
      this.checklistSelection.select(practice);
    });

    this.onToggle();
  }

  public onPracticeChange(practice: Practice, client: ClientPracticeView): void {
    if (this.canSelectMultiplePractices) {
      const selectedPracticeCount: number = this.selectedPractices.length;
      if (selectedPracticeCount > 1 || !this.selectedPractices.includes(practice)) {
        if (this.selectedClient.clientID != client.clientID) {
          this.checklistSelection.clear();
          this.checklistSelection.select(client);
        }
        this.checklistSelection.toggle(practice);
        this.onToggle();
      }
    } else if (practice.practiceGUID !== this.selectedPractices[0]?.practiceGUID) {
      this.checklistSelection.clear();
      this.checklistSelection.select(client);
      this.checklistSelection.select(practice);
    }

    this.onToggle();
  }

  private onToggle(): void {
    this.inputValue = this.setValue(',');
    this.tooltipValue = this.setValue('\n -');

    const value: ClientPracticeView = this.cloneService.deepClone(this.selectedClient);
    value.practices = this.selectedPractices;

    this.control.setValue(value);
  }

  //#endregion

  //#region Utility

  public descendantsAllSelected(viewModel: ClientPracticeView): boolean {
    const descendants = viewModel.practices ?? [];
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child: any) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  public descendantsPartiallySelected(viewModel: ClientPracticeView): boolean {
    const descendants = viewModel.practices ?? [];
    const result = descendants.some((child: any) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(viewModel);
  }

  private setValue(delimiter: string): string {
    if (this.selectedClient?.practices.length > 1) {
      return `${this.selectedClient.displayName}\n - ${this.selectedPractices
        .map((value: Practice) => `${value.description} ${value.hdsNumber ? '(' + value.hdsNumber + ')' : ''}`)
        .join(`${delimiter} `)}`;
    }

    return this.selectedClient.displayName;
  }

  //#endregion
}
