import { typeMap } from '@models/model-mapper';
import { ClientPracticeView } from '@models/client/client.model';

export class UserClientGroup {
  public userClientGroupID: number = 0;
  public name: string = '';
  public userID: number = 0;
  @typeMap(ClientPracticeView)
  public clients: ClientPracticeView[] = [];
}
