import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger, MenuPositionY } from '@angular/material/menu';

import { ActionOption } from '@models/action-menu.model';
import { FontSize, IconType } from '@models/icon/icon.model';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent implements AfterViewInit {
  @Input() actionOptions: ActionOption[] = [];
  @Input() color: ThemePalette | nil;
  @Input() disabled: boolean = false;
  @Input() disableRipple: boolean = false;
  @Input() element: any;
  @Input() fontSize: FontSize = 'small';
  @Input() icon: IconType = 'ellipsis';
  @Input() isOrdered: boolean = true;
  @Input() menuButtonLabel: string | nil;
  @Input() menuText: string | nil;
  @Input() submenu: boolean = false;
  @Input() tooltip: string = 'More actions...';
  @Input() useAlternateStyle: boolean = false;
  @Input() yPosition: MenuPositionY = 'below';

  @Output() menuOpened: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('actionMenuTrigger') actionMenuTrigger: MatMenuTrigger | nil;

  public isMenuOpened: boolean = false;

  public ngAfterViewInit(): void {
    if (this.actionMenuTrigger) {
      this.actionMenuTrigger.restoreFocus = false;
    }
  }

  //#region Events

  public onOpenClick(event: Event): void {
    event.stopPropagation();
    this.isMenuOpened = true;
    this.actionMenuTrigger?.openMenu();
    this.menuOpened.emit(null);
  }

  public onMenuClosed(): void {
    this.isMenuOpened = false;
  }

  //#endregion

  public openActionMenu(event: Event): void {
    this.actionMenuTrigger?.openMenu();
    this.onOpenClick(event);
  }

  public getTooltip(actionOption: ActionOption): string {
    if (actionOption.tooltipCallback(this.element)) {
      return actionOption.tooltipCallback(this.element);
    }

    return '';
  }
}
