import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'mat-tab-body, [appVerticalScrollbarDetected]',
})
export class VerticalScrollbarDetectedDirective {
  styleApplied: boolean = false;

  constructor(private elementRef: ElementRef) {}

  ngDoCheck() {
    if (
      !this.styleApplied &&
      this.elementRef &&
      this.elementRef.nativeElement &&
      this.elementRef.nativeElement.scrollWidth < this.elementRef.nativeElement.offsetWidth
    ) {
      this.elementRef.nativeElement.classList.add('vertical-scrollbar-padding');
      this.styleApplied = true;
    } else if (
      this.styleApplied &&
      this.elementRef &&
      this.elementRef.nativeElement &&
      this.elementRef.nativeElement.scrollWidth == this.elementRef.nativeElement.offsetWidth
    ) {
      this.elementRef.nativeElement.classList.remove('vertical-scrollbar-padding');
      this.styleApplied = false;
    }
  }
}
