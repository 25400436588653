import { Component, Input } from '@angular/core';

import { IconType } from '@models/icon/icon.model';
import { SideNavItem } from '@models/side-nav-item.model';

@Component({
  selector: 'app-sidenav-hidden-menu',
  templateUrl: './sidenav-hidden-menu.component.html',
  styleUrls: ['./sidenav-hidden-menu.component.scss'],
})
export class SideNavHiddenMenuComponent {
  @Input() model: SideNavItem[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isTop: boolean = true;

  public get icon(): IconType {
    return this.isTop ? 'caretUp' : 'caretDown';
  }
}
