import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject, Subscription } from 'rxjs';
import { first, throttleTime } from 'rxjs/operators';

import { IdleService } from '@services/idle.service';

@Component({
  selector: 'app-idle',
  templateUrl: './idle.component.html',
  styleUrls: ['./idle.component.scss'],
  animations: [
    trigger('hideShow', [
      state(
        'hide',
        style({
          'background-color': 'rgba(0, 0, 0, 0)',
          transform: 'scale(0.0)',
        }),
      ),
      state(
        'show',
        style({
          'background-color': 'rgba(0, 0, 0, 0.5)',
          transform: 'scale(1.0)',
        }),
      ),
      transition('show => hide', [animate(250)]),
      transition('hide => show', [animate(1000)]),
    ]),
  ],
  providers: [IdleService],
})
export class IdleComponent implements OnInit, OnDestroy {
  public hidden: boolean = true;
  public logoutSeconds: number = 0;

  private eventUnsubscribeFunctions: Function[] = [];
  private interval: NodeJS.Timeout | nil;
  private subscriptions: Subscription = new Subscription();

  private interactionEvent: Subject<void> = new Subject<void>();

  constructor(
    private idleService: IdleService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.idleService.timeoutCounter$.subscribe((s) => this.idleServiceTimeout()));
    this.subscriptions.add(this.idleService.timeoutReset$.subscribe((s) => this.idleServiceReset()));
    this.subscriptions.add(
      this.idleService.pollStart$
        .pipe(first((result: boolean) => result))
        .subscribe((s) => this.idleServicePollStart()),
    );
    this.subscriptions.add(this.idleService.pollStop$.subscribe((s) => this.idleServicePollStop()));
    this.subscriptions.add(this.interactionEvent.pipe(throttleTime(250)).subscribe(() => this.resetTimer()));
  }

  ngOnDestroy() {
    this.idleServicePollStop();
    this.subscriptions.unsubscribe();
  }

  private resetTimer() {
    this.idleService.resetPollCounter();
  }

  private idleServiceTimeout() {
    this.logoutSeconds = this.idleService.currentSecondsToLogout;
    this.hidden = false;
    this.interval = setInterval(() => {
      this.logoutSeconds = this.idleService.currentSecondsToLogout;
    }, 1000);
  }

  private idleServiceReset() {
    if (!this.hidden) {
      this.hidden = true;
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }

  private idleServicePollStart() {
    this.subscribeToEvents();
  }

  private idleServicePollStop() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.unsubscribeToEvents();
    this.hidden = true;
  }

  private unsubscribeToEvents() {
    for (let unsubscribeFunction of this.eventUnsubscribeFunctions) {
      unsubscribeFunction();
    }

    this.eventUnsubscribeFunctions = [];
  }

  private subscribeToEvents() {
    this.eventUnsubscribeFunctions.push(
      this.renderer.listen('document', 'mousemove', () => this.interactionEvent.next()),
    );
    this.eventUnsubscribeFunctions.push(
      this.renderer.listen('document', 'keypress', () => this.interactionEvent.next()),
    );
    this.eventUnsubscribeFunctions.push(this.renderer.listen('document', 'click', () => this.interactionEvent.next()));
    this.eventUnsubscribeFunctions.push(
      this.renderer.listen('document', 'mousewheel', () => this.interactionEvent.next()),
    );
    this.eventUnsubscribeFunctions.push(
      this.renderer.listen('document', 'contextmenu', () => this.interactionEvent.next()),
    );
    this.eventUnsubscribeFunctions.push(
      this.renderer.listen('document', 'touchstart', () => this.interactionEvent.next()),
    );
    this.eventUnsubscribeFunctions.push(
      this.renderer.listen('document', 'touchmove', () => this.interactionEvent.next()),
    );
  }
}
